import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  HStack,
  Select,
  Text,
  useToast,
  VStack,
  Icon,
} from "@chakra-ui/react";
import CustomInput from "../../components/input/CustomInput";
import CustomTextArea from "../../components/input/CustomTextArea";
import PasswordInput from "../../components/input/PasswordInput";
import Cookies from "js-cookie";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import GradiantBtn from "../../components/btn/GradiantBtn";
import { IoClose } from "react-icons/io5";

const AddDriver = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [area, setArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [subArea, setSubArea] = useState([]);
  const [selectedSubArea, setSelectedSubArea] = useState("");
  const [selectedSecondSubArea, setSelectedSecondSubArea] = useState([]);
  //
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [carPlateNumber, setCarPlateNumber] = useState("");
  const [carModel, setCarModel] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/api/area/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        setIsLoading(false);
        // console.log("zone list --------->", result.data);
        setArea(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedArea) {
      setSelectedSubArea("");
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/area/sub", {
          params: { areaId: selectedArea },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("sub list --------->", result.data);
          setSubArea(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [selectedArea]);

  const selectedSecondSubHandler = (value) => {
    // console.log("selectedSecondSubHandler -->", value, selectedSecondSubArea);
    if (selectedSecondSubArea.some((e) => e._id === value._id)) {
      setSelectedSecondSubArea(
        selectedSecondSubArea.filter((e) => e._id !== value._id)
      );
    } else {
      setSelectedSecondSubArea([...selectedSecondSubArea, value]);
    }
  };

  const secondSubRemoveHandler = (value) => {
    setSelectedSecondSubArea(
      selectedSecondSubArea.filter((e) => e._id !== value._id)
    );
  };

  const submitHandler = () => {
    // console.log(
    //   firstName,
    //   lastName,
    //   address,
    //   phoneNo,
    //   password,
    //   registerNumber,
    //   carPlateNumber,
    //   carModel,
    //   selectedSecondSubArea.map((e) => e._id)
    // );
    if (
      lastName &&
      firstName &&
      phoneNo &&
      address &&
      registerNumber &&
      password &&
      carModel &&
      carPlateNumber &&
      selectedSecondSubArea.length > 0
    ) {
      setIsDisabled(true);
      axios
        .post(
          "/api/driver/",
          {
            firstName: firstName,
            lastName: lastName,
            address: address,
            phoneNo: phoneNo,
            password: password,
            registerNumber: registerNumber,
            carPlateNumber: carPlateNumber,
            carModel: carModel,
            assignedSecondSubAreas: selectedSecondSubArea.map((e) => e._id),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            navigate("/driver");
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <VStack
        w={"3xl"}
        bg={"#F9F9F9"}
        rounded={"sm"}
        align={"flex-start"}
        justify={"flex-start"}
        px={"10"}
        py={"8"}
        spacing={4}
      >
        <HStack
          w={"full"}
          align={"flex-start"}
          justify={"flex-start"}
          spacing={4}
        >
          <VStack w={"full"}>
            {/* -------------- Овог ---------------- */}
            <CustomInput
              title={"Овог"}
              value={lastName}
              onChange={setLastName}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Нэр ---------------- */}
            <CustomInput
              title={"Нэр"}
              value={firstName}
              onChange={setFirstName}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Регистрийн дугаар ---------------- */}
            <CustomInput
              title={"Регистрийн дугаар"}
              value={registerNumber}
              onChange={setRegisterNumber}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Утас ---------------- */}
            <CustomInput
              title={"Утас"}
              value={phoneNo}
              onChange={setPhoneNo}
              type={"number"}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Нууц үг ---------------- */}
            <PasswordInput
              title={"Нууц үг"}
              value={password}
              onChange={setPassword}
              required={true}
              bgColor={"#fff"}
            />

            {/* -------------- Машины марк: ---------------- */}
            <CustomInput
              title={"Машины марк"}
              value={carModel}
              onChange={setCarModel}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Машины номер ---------------- */}
            <CustomInput
              title={"Машины номер"}
              value={carPlateNumber}
              onChange={setCarPlateNumber}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Гэрийн хаяг ---------------- */}
            <CustomTextArea
              title={"Гэрийн хаяг"}
              value={address}
              onChange={setAddress}
              required={true}
              bgColor={"#fff"}
            />
          </VStack>
          <VStack w={"full"}>
            <VStack w={"full"} align={"flex-start"} spacing={0}>
              <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                Дүүрэг:
              </Text>
              <Select
                w={"full"}
                bg={"#fff"}
                size={"md"}
                rounded={"md"}
                fontWeight={"normal"}
                fontSize={14}
                color={"#000"}
                value={selectedArea}
                onChange={(e) => setSelectedArea(e.target.value)}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
                _hover={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
              >
                <option selected hidden disabled value="">
                  -
                </option>
                {area.length > 0
                  ? area.map((e) => (
                      <option key={e._id} value={e._id}>
                        {e.areaName}
                      </option>
                    ))
                  : null}
              </Select>
            </VStack>
            <VStack w={"full"} align={"flex-start"} spacing={0}>
              <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                Чиглэл:
              </Text>
              <Select
                w={"full"}
                bg={"#fff"}
                size={"md"}
                rounded={"md"}
                fontWeight={"normal"}
                fontSize={14}
                color={"#000"}
                value={selectedSubArea}
                onChange={(e) => setSelectedSubArea(e.target.value)}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
                _hover={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
              >
                <option selected hidden disabled value="">
                  -
                </option>
                {subArea.length > 0
                  ? subArea.map((e) => (
                      <option key={e._id} value={e._id}>
                        {e.areaName}
                      </option>
                    ))
                  : null}
              </Select>
            </VStack>
            {selectedSubArea.length > 0 ? (
              <VStack w={"full"} align={"flex-start"} spacing={0}>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Орчим:
                </Text>
                <VStack w={"full"} align={"flex-start"} spacing={1}>
                  {subArea
                    .filter((e) => e._id === selectedSubArea)
                    .map((filteredSubArea) =>
                      filteredSubArea.secondSubAreas.map((a) => (
                        <Checkbox
                          key={a._id}
                          colorScheme="orange"
                          size={"md"}
                          value={selectedSecondSubArea}
                          onChange={() => selectedSecondSubHandler(a)}
                          isChecked={selectedSecondSubArea.some(
                            (e) => e._id === a._id
                          )}
                          css={`
                            > span:first-of-type {
                              box-shadow: unset;
                            }
                          `}
                        >
                          <Text
                            fontSize={14}
                            color="#000"
                            fontWeight={"normal"}
                          >
                            {a.areaName}
                          </Text>
                        </Checkbox>
                      ))
                    )}
                </VStack>
              </VStack>
            ) : null}
            {selectedSecondSubArea.length > 0 ? (
              <VStack w={"full"} align={"flex-start"} spacing={0}>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Сонгогдсон орчим:
                </Text>
                <VStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"flex-start"}
                  borderWidth={"thin"}
                  rounded={"md"}
                  bg={"#fff"}
                  py={"2"}
                  px={"2"}
                  spacing={2}
                >
                  {selectedSecondSubArea.map((e, i) => (
                    <HStack
                      key={i}
                      w={"full"}
                      bg={"#f9f9f9"}
                      rounded={"md"}
                      py={"2"}
                      pl={"3"}
                      pr={"2"}
                      align={"flex-start"}
                      justify={"space-between"}
                    >
                      <Text
                        key={e._id}
                        fontSize={14}
                        color="#000"
                        fontWeight={"medium"}
                      >
                        {i + 1 + ". "}
                        <Text as={"span"} fontWeight={"normal"}>
                          {e.areaName}
                        </Text>
                      </Text>

                      <Icon
                        as={IoClose}
                        w={"5"}
                        h={"5"}
                        color={"#ff0000"}
                        onClick={() => secondSubRemoveHandler(e)}
                        cursor={"pointer"}
                      />
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            ) : null}
          </VStack>
        </HStack>
        <Divider />
        <HStack w={"60"} alignSelf={"flex-end"}>
          <GradiantBtn
            title={"Хадгалах"}
            onClick={submitHandler}
            isDisabled={
              firstName &&
              lastName &&
              address &&
              phoneNo &&
              password &&
              registerNumber &&
              carPlateNumber &&
              carModel &&
              selectedSecondSubArea.length > 0
                ? false
                : true
            }
          />
        </HStack>
      </VStack>
    </VStack>
  );
};

export default AddDriver;
