import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { HStack, Stack, VStack } from "@chakra-ui/react";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";

const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();
  const accessToken = Cookie.get("accessToken");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);

  return (
    <HStack
      w={"100vw"}
      h={"100vh"}
      maxH={"100vh"}
      spacing={0}
      overflowX={"hidden"}
    >
      <Sidebar />
      <VStack w={"full"} maxH={"100vh"} minH={"100vh"} spacing={0}>
        <Header />
        <Stack w={"full"} p={"6"} overflowY={"auto"}>
          {element}
        </Stack>
      </VStack>
    </HStack>
  );
};

export default PrivateRoute;
