import React from "react";
import { Center, Icon } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const BackButton = ({ currentPage, toLink }) => {
  const navigate = useNavigate();
  // console.log(currentPage, toLink);

  return (
    <Center
      borderRadius="md"
      cursor="pointer"
      onClick={() => {
        currentPage && toLink
          ? navigate(toLink, { state: { page: currentPage } })
          : navigate(-1);
      }}
    >
      <Icon as={HiOutlineArrowNarrowLeft} color={"#000"} w={5} h={5} />
    </Center>
  );
};

export default BackButton;
