import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../utils/axios";
import ListTable from "./ListTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import GradiantBtn from "../../components/btn/GradiantBtn";
import CustomInput from "../../components/input/CustomInput";
import PasswordInput from "../../components/input/PasswordInput";
import CustomTextArea from "../../components/input/CustomTextArea";

const Operator = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState(null);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearData = () => {
    setLastName("");
    setFirstName("");
    setPhoneNo("");
    setUsername("");
    setPassword("");
    setAddress("");
    setGender(null);
  };

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/admin/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("admin list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const submitHandler = () => {
    if (
      lastName &&
      firstName &&
      phoneNo &&
      address &&
      username &&
      password &&
      gender
    ) {
      setIsDisabled(true);
      axios
        .post(
          "/api/admin/",
          {
            username: username,
            password: password,
            firstName: firstName,
            lastName: lastName,
            sex: gender,
            address: address,
            phoneNo: parseInt(phoneNo),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            clearData();
            onClose();
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const headerData = [
    "#",
    "Овог Нэр",
    "Утас",
    "Хүйс",
    "Хаяг",
    "Нэвтрэх нэр",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack alignSelf={"flex-end"}>
        <GradiantBtn
          title={"Оператор нэмэх"}
          onClick={() => onOpen()}
          isDisabled={isDisabled}
        />
      </HStack>
      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Оператор нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"} spacing={2}>
              {/* -------------- Овог ---------------- */}
              <CustomInput
                title={"Овог"}
                value={lastName}
                onChange={setLastName}
                required={true}
              />
              {/* -------------- Нэр ---------------- */}
              <CustomInput
                title={"Нэр"}
                value={firstName}
                onChange={setFirstName}
                required={true}
              />
              {/* -------------- Утас ---------------- */}
              <CustomInput
                title={"Утас"}
                value={phoneNo}
                onChange={setPhoneNo}
                type={"number"}
                required={true}
              />
              {/* -------------- Нэвтрэх нэр ---------------- */}
              <CustomInput
                title={"Нэвтрэх нэр"}
                value={username}
                onChange={setUsername}
                required={true}
              />
              {/* -------------- Нууц үг ---------------- */}
              <PasswordInput
                title={"Нууц үг"}
                value={password}
                onChange={setPassword}
                required={true}
              />
              {/* -------------- Цол ---------------- */}
              <VStack w={"full"} align={"flex-start"} justify={"flex-end"}>
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  <Text as={"span"} color={"#ff0000"}>
                    *
                  </Text>{" "}
                  Хүйс:
                </Text>
                <HStack w={"72"} spacing={6}>
                  <Checkbox
                    colorScheme="orange"
                    size={"md"}
                    value={gender}
                    onChange={() => setGender("male")}
                    isChecked={gender === "male" ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эрэгтэй
                    </Text>
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    size={"md"}
                    value={gender}
                    onChange={() => setGender("female")}
                    isChecked={gender === "female" ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эмэгтэй
                    </Text>
                  </Checkbox>
                </HStack>
              </VStack>
              {/* -------------- Хаяг ---------------- */}
              <CustomTextArea
                title={"Хаяг"}
                value={address}
                onChange={setAddress}
                required={true}
                onKeyDown={(e) => {
                  if (
                    lastName &&
                    firstName &&
                    phoneNo &&
                    address &&
                    username &&
                    password &&
                    gender
                  ) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                clearData();
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={submitHandler}
              isDisabled={
                lastName &&
                firstName &&
                phoneNo &&
                address &&
                username &&
                password &&
                gender &&
                !isDisabled
                  ? false
                  : true
              }
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Operator;
