import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  MenuItem,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Checkbox,
  ModalFooter,
  IconButton,
  Flex,
  Portal,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../utils/axios";
import Cookies from "js-cookie";
import DeleteModal from "../../components/modal/DeleteModal";
import CustomInput from "../../components/input/CustomInput";
import PasswordInput from "../../components/input/PasswordInput";
import CustomTextArea from "../../components/input/CustomTextArea";
import { useNavigate } from "react-router-dom";

const ListTable = ({ data, headerData, isLoading, setTrigger }) => {
  // console.log("AdminList Table -->", data);
  const [isDisabled, setIsDisabled] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState(null);
  const [editPass, setEditPass] = useState(false);
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearData = () => {
    setLastName("");
    setFirstName("");
    setPhoneNo("");
    setUsername("");
    setPassword("");
    setAddress("");
    setGender(null);
  };

  const editHandler = (value) => {
    setAdminId(value._id);
    setLastName(value.lastName);
    setFirstName(value.firstName);
    setPhoneNo(value.phoneNo);
    setUsername(value.username);
    setAddress(value.address);
    setGender(value.sex);
  };

  const deleteHandler = () => {
    if (adminId) {
      setIsDisabled(true);
      axios
        .delete("/api/area/", {
          data: {
            areaId: adminId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setAdminId("");
          setShowModal(false);
          setTrigger(true);
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const submitHandler = () => {
    if (adminId) {
      setIsDisabled(true);
      axios
        .put(
          "/api/admin/",
          {
            adminId: adminId,
            username: username,
            firstName: firstName,
            lastName: lastName,
            sex: gender,
            address: address,
            phoneNo: parseInt(phoneNo),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setAdminId("");
            onClose();
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const passwordHandler = () => {
    if (adminId && password) {
      setIsDisabled(true);
      axios
        .put(
          "/api/admin/change_password",
          {
            adminId: adminId,
            newPassword: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setAdminId("");
            onClose();
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };
  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"} className="primaryColor">
          {headerData ? (
            <Thead bg={"#f9f9f9"}>
              <Tr>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {!isLoading && data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {i + 1}
                      </Text>
                    </Td>
                    {/* ------------ Дүүрэг ------------ */}
                    <Td
                      minW={"xs"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {e.areaName ? e.areaName : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Чиглэл ------------ */}
                    <Td
                      w={"full"}
                      // w={"xs"}
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Wrap w={"full"} spacing={2}>
                        {e.subAreas && e.subAreas.length > 0 ? (
                          e.subAreas.map((subArea, i) => (
                            <WrapItem key={i}>
                              <Text
                                fontSize={14}
                                fontWeight={"normal"}
                                color={"#000"}
                              >
                                {subArea}
                                {i !== e.subAreas.length - 1 && ", "}
                              </Text>
                            </WrapItem>
                          ))
                        ) : (
                          <WrapItem>
                            <Text
                              fontSize={14}
                              fontWeight={"normal"}
                              color={"#000"}
                            >
                              -
                            </Text>
                          </WrapItem>
                        )}
                      </Wrap>
                    </Td>
                    <Td w={"20"} py={"2"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          h={"8"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>

                        <Portal>
                          <MenuList
                            minW="24"
                            w={"max-content"}
                            p={"0"}
                            rounded={"none"}
                            shadow={"md"}
                          >
                            <MenuItem
                              color={"#000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() =>
                                navigate("/zone/detail", { state: e })
                              }
                            >
                              Засварлах
                            </MenuItem>
                            <MenuItem
                              color={"#ff0000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                setAdminId(e._id);
                                setShowModal(true);
                              }}
                            >
                              Устгах
                            </MenuItem>
                          </MenuList>
                        </Portal>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#F07F1F"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      {/* ---------------------------------------- delete mainArea ------------------------------------------- */}
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        warntext={
          "Тухайн дүүргийг устгаснаар хамаарагдах бүх чиглэл, орчим болон захиалга устана гэдгийг анхаарна уу!"
        }
        isDisable={isDisabled}
        deleteHandler={deleteHandler}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Оператор нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"} spacing={2}>
              {/* -------------- Овог ---------------- */}
              <CustomInput
                title={"Овог"}
                value={lastName}
                onChange={setLastName}
                required={true}
              />
              {/* -------------- Нэр ---------------- */}
              <CustomInput
                title={"Нэр"}
                value={firstName}
                onChange={setFirstName}
                required={true}
              />
              {/* -------------- Утас ---------------- */}
              <CustomInput
                title={"Утас"}
                value={phoneNo}
                onChange={setPhoneNo}
                type={"number"}
                required={true}
              />
              {/* -------------- Нэвтрэх нэр ---------------- */}
              <CustomInput
                title={"Нэвтрэх нэр"}
                value={username}
                onChange={setUsername}
                required={true}
              />
              {/* -------------- Нууц үг ---------------- */}
              {editPass ? (
                <HStack w={"full"}>
                  <PasswordInput
                    title={"Нууц үг"}
                    value={password}
                    onChange={setPassword}
                    required={true}
                  />
                  <IconButton
                    isLoading={isDisabled}
                    variant="solid"
                    alignSelf={"flex-end"}
                    colorScheme="orange"
                    aria-label="Done"
                    fontSize="15"
                    icon={<Icon as={IoMdCheckmark} />}
                    onClick={() => passwordHandler()}
                    isDisabled={password && !isDisabled ? false : true}
                    _focusVisible={{
                      outline: "none",
                    }}
                  />
                  <IconButton
                    variant="solid"
                    alignSelf={"flex-end"}
                    colorScheme="gray"
                    aria-label="Close"
                    fontSize="15"
                    icon={<Icon as={IoMdClose} />}
                    onClick={() => {
                      setEditPass(false);
                      setPassword("");
                    }}
                    _focusVisible={{
                      outline: "none",
                    }}
                  />
                </HStack>
              ) : (
                <Center
                  transition={"ease-in-out .3s"}
                  w={"full"}
                  bg={"#ddd"}
                  h={"10"}
                  rounded={"md"}
                  cursor={"pointer"}
                  _hover={{
                    bg: "#eee",
                  }}
                  onClick={() => setEditPass(true)}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    Нууц үг солих
                  </Text>
                </Center>
              )}
              {/* -------------- Цол ---------------- */}
              <VStack w={"full"} align={"flex-start"} justify={"flex-end"}>
                <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                  <Text as={"span"} color={"#ff0000"}>
                    *
                  </Text>{" "}
                  Хүйс:
                </Text>
                <HStack w={"72"} spacing={6}>
                  <Checkbox
                    colorScheme="orange"
                    size={"md"}
                    value={gender}
                    onChange={() => setGender("male")}
                    isChecked={gender === "male" ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эрэгтэй
                    </Text>
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    size={"md"}
                    value={gender}
                    onChange={() => setGender("female")}
                    isChecked={gender === "female" ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эмэгтэй
                    </Text>
                  </Checkbox>
                </HStack>
              </VStack>
              {/* -------------- Хаяг ---------------- */}
              <CustomTextArea
                title={"Хаяг"}
                value={address}
                onChange={setAddress}
                required={true}
                onKeyDown={(e) => {
                  if (
                    lastName &&
                    firstName &&
                    phoneNo &&
                    address &&
                    username &&
                    password &&
                    gender
                  ) {
                    if (e.key === "Enter") {
                      submitHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                clearData();
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={submitHandler}
              isDisabled={
                lastName &&
                firstName &&
                phoneNo &&
                address &&
                username &&
                gender &&
                !isDisabled
                  ? false
                  : true
              }
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ListTable;
