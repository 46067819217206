import React from "react";
import { Button } from "@chakra-ui/react";

const GradiantBtn = ({
  title,
  onClick,
  isDisabled,
  isLoading,
  loadingText,
}) => {
  return (
    <Button
      w={"full"}
      fontSize={14}
      fontWeight={"medium"}
      color={"#fff"}
      bgGradient={"linear(to-l, #F95077, #F07F1F)"}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      loadingText={loadingText}
      _focusVisible={{
        outline: "none",
      }}
      _hover={{
        bgGradient: "linear(to-l, #F95C81, #F18529)",
      }}
    >
      {title}
    </Button>
  );
};

export default GradiantBtn;
