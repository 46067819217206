import React from "react";
import { Flex, HStack, Image, Text, VStack, Icon } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { BsPinMapFill } from "react-icons/bs";
import { navData } from "./SidebarData";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("location ->", location);
  return (
    <Flex
      w={"60"}
      minW={"60"}
      h="100vh"
      borderRightWidth={"thin"}
      borderColor={"#eee"}
      zIndex={999}
      bg={"#F9F9F9"}
      align={"flex-start"}
      justify={"flex-start"}
      display={["none", "none", "none", "none", "flex", "flex"]}
    >
      <VStack
        w={"full"}
        align={"flex-start"}
        justify={"flex-start"}
        spacing={4}
      >
        <Image
          src={logo}
          w={"20"}
          h={"16"}
          objectFit={"contain"}
          onClick={() => navigate("/")}
          cursor={"pointer"}
        />
        <VStack w={"full"} spacing={0}>
          {navData.map((data, index) => (
            <HStack
              transition={"ease-in-out .1s"}
              key={index}
              w={"full"}
              h={"12"}
              bg={
                (location.pathname === "/order" && data.toLink === "/") ||
                location.pathname.split("/")[1] === data.toLink.split("/")[1]
                  ? "#fff"
                  : "transparent"
              }
              pl={"6"}
              pr={"4"}
              borderRightWidth={"4px"}
              borderRightColor={
                (location.pathname === "/order" && data.toLink === "/") ||
                location.pathname.split("/")[1] === data.toLink.split("/")[1]
                  ? "#F07F1F"
                  : "transparent"
              }
              onClick={() => navigate(data.toLink)}
              cursor={"pointer"}
              _hover={{
                borderColor: "#F07F1F",
                bg: "#fff",
              }}
            >
              <Icon as={data.icon} />
              <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                {data.title}
              </Text>
            </HStack>
          ))}
        </VStack>
      </VStack>
    </Flex>
  );
};

export default Sidebar;
