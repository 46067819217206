import React, { useContext } from "react";
import NotFound from "./pages/NotFound";
import Login from "./pages/auth/Login";
import PrivateRoute from "./utils/route/PrivateRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserContext from "./utils/context/UserContext";
import Dashboard from "./pages/dashboard/Dashboard";
import Order from "./pages/order/Order";
import Product from "./pages/product/Product";
import Driver from "./pages/driver/Driver";
import Zone from "./pages/zone/Zone";
import Operator from "./pages/operator/Operator";
import Profile from "./pages/profile/Profile";
import ZoneDetail from "./pages/zone/detail/ZoneDetail";
import AddDriver from "./pages/driver/AddDriver";
import DriverArea from "./pages/driver/DriverArea";
import CreateOrder from "./pages/order/CreateOrder";
import DriverDetail from "./pages/driver/detail/DriverDetail";

const App = () => {
  const context = useContext(UserContext);

  return (
    <BrowserRouter>
      <Routes>
        {/* --------------------------
                    Public Routes
            -------------------------- */}
        <Route path="/login" element={<Login />} />

        {/* --------------------------
                    Protected Routes
            -------------------------- */}
        <Route path="/" element={<PrivateRoute element={<Order />} />} />
        <Route
          path="/order"
          element={<PrivateRoute element={<CreateOrder />} />}
        />
        <Route
          path="/product"
          element={<PrivateRoute element={<Product />} />}
        />
        <Route path="/driver" element={<PrivateRoute element={<Driver />} />} />
        <Route
          path="/driver/add"
          element={<PrivateRoute element={<AddDriver />} />}
        />
        <Route
          path="/driver/area"
          element={<PrivateRoute element={<DriverArea />} />}
        />
        <Route
          path="/driver/detail"
          element={<PrivateRoute element={<DriverDetail />} />}
        />
        <Route path="/zone" element={<PrivateRoute element={<Zone />} />} />
        <Route
          path="/zone/detail"
          element={<PrivateRoute element={<ZoneDetail />} />}
        />
        <Route
          path="/operator"
          element={<PrivateRoute element={<Operator />} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} />}
        />

        {/* --------------------------
                      Not Found
            -------------------------- */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
