import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Icon,
  Center,
  Image,
} from "@chakra-ui/react";
import { MdOutlineImageNotSupported } from "react-icons/md";

const DetailTable = ({ data, headerData, isLoading, setTrigger }) => {
  return (
    <TableContainer w={"full"} pos={"relative"}>
      <Table border={"1px"} borderColor={"#ECECEC"} size={"sm"}>
        {headerData ? (
          <Thead>
            <Tr bg={"#F6F6F6"}>
              {headerData.map((e, i) => (
                <Th
                  key={i}
                  borderRight={"1px"}
                  borderRightColor={"#ECECEC"}
                  fontSize={13}
                  fontWeight={"medium"}
                  textTransform={"initial"}
                >
                  {e}
                </Th>
              ))}
            </Tr>
          </Thead>
        ) : null}
        {data.length > 0
          ? data.map((e, i) => (
              <Tbody key={i} bg={"#fff"}>
                <Tr w={"full"}>
                  <Td
                    w={"6"}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    align={"center"}
                    justify={"flex-start"}
                    color={"#000"}
                    fontWeight={"normal"}
                    fontSize={13}
                  >
                    <Text>{i + 1}</Text>
                  </Td>
                  {/* ------------ Зураг ------------ */}
                  <Td
                    w={"28"}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    justify={"flex-start"}
                  >
                    {e.product?.image ? (
                      <Image
                        src={e.product.image}
                        w={"8"}
                        h={"8"}
                        objectFit={"contain"}
                      />
                    ) : (
                      <Center w={"8"} h={"8"}>
                        <Icon
                          as={MdOutlineImageNotSupported}
                          w={"6"}
                          h={"6"}
                          color={"#979797"}
                        />
                      </Center>
                    )}
                  </Td>
                  {/* ------------ Нэр ------------ */}
                  <Td
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    justify={"flex-start"}
                    maxW={"52"}
                    whiteSpace={"pre-wrap"}
                  >
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      {e.product?.name ? e.product.name : "-"}
                    </Text>
                  </Td>

                  {/* ------------ Үнэ ------------ */}
                  <Td
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    justify={"flex-start"}
                  >
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      {e.product?.price
                        ? e.product.price.toLocaleString() + "₮"
                        : "-"}
                    </Text>
                  </Td>
                  {/* ------------ Тоо ширхэг ------------ */}
                  {e.quantity ? (
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                        {e.quantity + "ш"}
                      </Text>
                    </Td>
                  ) : null}
                  {/* ------------ Нийт үнэ ------------ */}
                  <Td
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    justify={"flex-start"}
                  >
                    <Text fontSize={13} fontWeight={"normal"} color={"#000"}>
                      {e.quantity && e.product?.price
                        ? (e.quantity * e.product.price).toLocaleString() + "₮"
                        : e.priceEach
                        ? e.priceEach.toLocaleString() + "₮"
                        : "-"}
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            ))
          : null}
      </Table>
    </TableContainer>
  );
};

export default DetailTable;
