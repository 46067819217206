import axios from "axios";
import Cookies from "js-cookie";
const source = axios.CancelToken.source();
const token = Cookies.get("accessToken");
let instance;

instance = axios.create({
  // baseURL: "http://192.168.1.247:4550",
  // baseURL: "http://localhost:4550",
  baseURL: "http://103.153.141.7:4550",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  cancelToken: source.token,
});
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
