import React, { useEffect, useState } from "react";
import {
  HStack,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Icon,
  Image,
  Center,
  IconButton,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import axios from "../../utils/axios";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { FiInfo, FiPlus } from "react-icons/fi";
import { MdOutlineImageNotSupported } from "react-icons/md";
import RightSide from "./create/RightSide";
import { IoSearch } from "react-icons/io5";

const CreateOrder = () => {
  const location = useLocation();
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  //
  const [selectedProduct, setSelectedProduct] = useState(
    location?.state?.products
      ? location.state.products.map((value) => ({
          image: value.product?.image || "",
          name: value.product?.name || "-",
          price: value.product?.price || value.priceEach,
          stock: value.product?.stock || "",
          _id: value.product?._id || "",
          quantity: value.quantity,
          description: "",
        }))
      : []
  );
  const [productName, setProductName] = useState("");
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/product/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("product list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const addHandler = (value) => {
    if (selectedProduct.some((e) => e._id === value._id)) {
      const clone = [...selectedProduct];
      const updatedClone = clone.map((e) => {
        if (e._id === value._id) {
          return {
            ...e,
            quantity: e.quantity + 1,
          };
        }
        return e;
      });
      setSelectedProduct(updatedClone);
    } else {
      setSelectedProduct([
        ...selectedProduct,
        {
          image: value.image,
          name: value.name,
          price: value.price,
          stock: value.stock,
          _id: value._id,
          quantity: 1,
          description: "",
        },
      ]);
    }
  };

  useEffect(() => {
    if (productName) {
      // setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/order/product_name", {
          params: { name: productName },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // setIsLoading(false);
          // console.log("search list --------->", result.data);
          setSearchData(result.data.data);
          // setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          // setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    } else {
      setSearchData([]);
    }
  }, [productName]);

  return (
    <VStack
      w={"full"}
      pb={"2"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <Stack
        direction={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        w={"full"}
        align={"flex-start"}
        justify={"flex-start"}
        spacing={4}
      >
        <VStack
          w={"full"}
          minH={"20"}
          bg={"#f9f9f9"}
          borderWidth={"thin"}
          align={"flex-start"}
          py={"3"}
          px={"4"}
        >
          <Text fontSize={16} color={"#000"} fontWeight={"medium"}>
            Бараа
          </Text>
          <Divider />
          <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
            {isLoading ? (
              <Spinner color="#F07F1F" alignSelf={"center"} />
            ) : data.length > 0 ? (
              <VStack w={"full"}>
                <InputGroup>
                  <Input
                    placeholder="Бараа хайх ..."
                    bg={"#fff"}
                    fontSize={14}
                    fontWeight={"normal"}
                    color={"#000"}
                    value={productName}
                    onChange={(e) =>
                      setProductName(
                        e.target.value.replace(/\s+/g, " ").trimStart()
                      )
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                      const pasteData = e.clipboardData.getData("text");
                      const cleanedData = pasteData.replace(/\s+/g, " ").trim();
                      setProductName(cleanedData);
                    }}
                    _focusVisible={{
                      outline: "none",
                      borderColor: "#F07F1F",
                    }}
                    _hover={{
                      outline: "none",
                    }}
                  />
                  <InputRightElement>
                    <IoSearch color="#808080" />
                  </InputRightElement>
                </InputGroup>

                <VStack
                  w={"full"}
                  maxH={"70vh"}
                  overflowY={"scroll"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "0",
                    },
                  }}
                >
                  {productName ? (
                    searchData.length > 0 && productName ? (
                      searchData.map((product, i) => (
                        <HStack key={product._id} w={"full"}>
                          <HStack
                            w={"full"}
                            minH={"14"}
                            px={"2"}
                            align={"center"}
                            bg={"#fff"}
                            borderWidth={"thin"}
                          >
                            {product.image ? (
                              <Image
                                src={product.image}
                                w={"10"}
                                h={"10"}
                                objectFit={"contain"}
                              />
                            ) : (
                              <Center w={"10"} h={"10"}>
                                <Icon
                                  as={MdOutlineImageNotSupported}
                                  w={"6"}
                                  h={"6"}
                                  color={"#979797"}
                                />
                              </Center>
                            )}
                            <VStack
                              w={"max-content"}
                              align={"flex-start"}
                              spacing={0}
                            >
                              <Text
                                fontSize={14}
                                color={"#000"}
                                fontWeight={"medium"}
                              >
                                {product.name}
                              </Text>
                              <Stack
                                direction={[
                                  "column",
                                  "column",
                                  "row",
                                  "row",
                                  "row",
                                  "row",
                                ]}
                                spacing={[0, 0, 2, 2, 2, 2]}
                              >
                                <Text
                                  fontSize={14}
                                  color={"#000"}
                                  fontWeight={"normal"}
                                >
                                  Үнэ:{" "}
                                  <Text as={"span"} fontWeight={"medium"}>
                                    {product.price.toLocaleString() + "₮"}
                                  </Text>
                                </Text>
                                <Text
                                  fontSize={14}
                                  color={"#000"}
                                  fontWeight={"normal"}
                                >
                                  Тоо ширхэг:{" "}
                                  <Text
                                    as={"span"}
                                    fontWeight={"medium"}
                                    color={
                                      product.stock <= 30 && product.stock >= 11
                                        ? "orange"
                                        : product.stock <= 10
                                        ? "#ff0000"
                                        : "#000"
                                    }
                                  >
                                    {product.stock + " ш"}
                                  </Text>
                                </Text>
                              </Stack>
                            </VStack>
                          </HStack>

                          <IconButton
                            colorScheme="orange"
                            size="sm"
                            icon={<FiPlus />}
                            onClick={() => addHandler(product)}
                          />
                        </HStack>
                      ))
                    ) : (
                      <VStack w={"full"} spacing={1} py={"2"}>
                        <Icon as={FiInfo} w={"5"} h={"5"} />
                        <Text
                          fontSize={13}
                          color={"#000"}
                          fontWeight={"normal"}
                        >
                          Мэдээлэл олдсонгүй
                        </Text>
                      </VStack>
                    )
                  ) : (
                    data.map((product, i) => (
                      <HStack key={product._id} w={"full"}>
                        <HStack
                          w={"full"}
                          minH={"14"}
                          px={"2"}
                          align={"center"}
                          bg={"#fff"}
                          borderWidth={"thin"}
                        >
                          {product.image ? (
                            <Image
                              src={product.image}
                              w={"10"}
                              h={"10"}
                              objectFit={"contain"}
                            />
                          ) : (
                            <Center w={"10"} h={"10"}>
                              <Icon
                                as={MdOutlineImageNotSupported}
                                w={"6"}
                                h={"6"}
                                color={"#979797"}
                              />
                            </Center>
                          )}
                          <VStack
                            w={"max-content"}
                            align={"flex-start"}
                            spacing={0}
                          >
                            <Text
                              fontSize={14}
                              color={"#000"}
                              fontWeight={"medium"}
                            >
                              {product.name}
                            </Text>
                            <Stack
                              direction={[
                                "column",
                                "column",
                                "row",
                                "row",
                                "row",
                                "row",
                              ]}
                              spacing={[0, 0, 2, 2, 2, 2]}
                            >
                              <Text
                                fontSize={14}
                                color={"#000"}
                                fontWeight={"normal"}
                              >
                                Үнэ:{" "}
                                <Text as={"span"} fontWeight={"medium"}>
                                  {product.price.toLocaleString() + "₮"}
                                </Text>
                              </Text>
                              <Text
                                fontSize={14}
                                color={"#000"}
                                fontWeight={"normal"}
                              >
                                Тоо ширхэг:{" "}
                                <Text
                                  as={"span"}
                                  fontWeight={"medium"}
                                  color={
                                    product.stock <= 30 && product.stock >= 11
                                      ? "orange"
                                      : product.stock <= 10
                                      ? "#ff0000"
                                      : "#000"
                                  }
                                >
                                  {product.stock + " ш"}
                                </Text>
                              </Text>
                            </Stack>
                          </VStack>
                        </HStack>

                        {/* <HStack
                    w={"max-content"}
                    minW={"14"}
                    h={"14"}
                    bg={"#fff"}
                    borderWidth={"thin"}
                    align={"center"}
                    justify={"center"}
                  > */}
                        <IconButton
                          colorScheme="orange"
                          size="sm"
                          icon={<FiPlus />}
                          onClick={() => addHandler(product)}
                        />
                        {/* </HStack> */}
                      </HStack>
                    ))
                  )}
                </VStack>
              </VStack>
            ) : (
              <VStack w={"full"} spacing={1} py={"2"}>
                <Icon as={FiInfo} w={"5"} h={"5"} />
                <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                  Мэдээлэл олдсонгүй
                </Text>
              </VStack>
            )}
          </VStack>
        </VStack>
        <VStack
          w={"full"}
          minH={"20"}
          bg={"#f9f9f9"}
          borderWidth={"thin"}
          align={"flex-start"}
          py={"3"}
          px={"4"}
        >
          <Text fontSize={16} color={"#000"} fontWeight={"medium"}>
            Захиалга
          </Text>
          <Divider />

          {/* ----------------------------
                          Right
              ---------------------------- */}
          <RightSide
            otherData={location?.state ? location.state : ""}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setTrigger={setTrigger}
          />
        </VStack>
      </Stack>
    </VStack>
  );
};

export default CreateOrder;
