import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  MenuItem,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  ModalFooter,
  Image,
  Portal,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../utils/axios";
import Cookies from "js-cookie";
import DeleteModal from "../../components/modal/DeleteModal";
import CustomInput from "../../components/input/CustomInput";
import { MdOutlineImageNotSupported } from "react-icons/md";
import ImageUpload from "../../components/upload/ImageUpload";
import { IoClose } from "react-icons/io5";

const ListTable = ({ data, headerData, isLoading, setTrigger, indexArr }) => {
  // console.log("AdminList Table -->", data);
  const [isDisabled, setIsDisabled] = useState(false);
  const [productId, setProductId] = useState("");
  const [showModal, setShowModal] = useState(false);
  //
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [image, setImage] = useState("");
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearData = () => {
    setProductName("");
    setPrice("");
    setStock("");
    setImage("");
  };

  const editHandler = (value) => {
    setProductId(value._id);
    setProductName(value.name);
    setPrice(value.price);
    setStock(value.stock);
    setImage(value.image);
  };

  const deleteHandler = () => {
    if (productId) {
      setIsDisabled(true);
      axios
        .delete("/api/product/", {
          data: {
            productId: productId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setProductId("");
          setShowModal(false);
          setTrigger(true);
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const submitHandler = () => {
    if (productId) {
      setIsDisabled(true);
      axios
        .put(
          "/api/product/",
          {
            productId: productId,
            image: image,
            price: parseInt(price),
            name: productName,
            stock: parseInt(stock),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setProductId("");
            onClose();
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"} size={"sm"}>
          {headerData ? (
            <Thead bg={"#f9f9f9"} h={"8"}>
              <Tr>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {!isLoading && data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {/* {i + 1} */}
                        {indexArr[i]}
                      </Text>
                    </Td>
                    {/* ------------ Зураг ------------ */}
                    <Td
                      w={"28"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      {e.image ? (
                        <Image
                          src={e.image}
                          w={"10"}
                          h={"10"}
                          objectFit={"contain"}
                        />
                      ) : (
                        <Center w={"10"} h={"10"}>
                          <Icon
                            as={MdOutlineImageNotSupported}
                            w={"6"}
                            h={"6"}
                            color={"#979797"}
                          />
                        </Center>
                      )}
                    </Td>
                    {/* ------------ Барааны нэр ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      whiteSpace={"pre-wrap"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {e.name ? e.name : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Үнэ ------------ */}
                    <Td
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {e.price ? e.price.toLocaleString() + "₮" : "-"}
                      </Text>
                    </Td>
                    {/* ------------ stock ------------ */}
                    <Td
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={"normal"}
                        color={
                          e.stock <= 30 && e.stock >= 11
                            ? "orange"
                            : e.stock <= 10
                            ? "#ff0000"
                            : "#000"
                        }
                      >
                        {e.stock ? e.stock + " ш" : "-"}
                      </Text>
                    </Td>
                    <Td w={"20"} py={"1"}>
                      <Menu gutter={1} autoSelect={false} placement={"bottom"}>
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          h={"8"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>

                        <Portal>
                          <MenuList
                            minW="24"
                            w={"max-content"}
                            p={"0"}
                            rounded={"none"}
                            shadow={"md"}
                          >
                            <MenuItem
                              color={"#000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                editHandler(e);
                                onOpen();
                              }}
                            >
                              Засварлах
                            </MenuItem>
                            <MenuItem
                              color={"#ff0000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                setProductId(e._id);
                                setShowModal(true);
                              }}
                            >
                              Устгах
                            </MenuItem>
                          </MenuList>
                        </Portal>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#F07F1F"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      {/* ---------------------------------------- delete product ------------------------------------------- */}
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        warntext={
          "Энэхүү үйлдлийг хийснээр барааг агуулсан бүх захиалга устахыг анхаарна уу!"
        }
        isDisable={isDisabled}
        deleteHandler={deleteHandler}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Барааны мэдээлэл шинэчлэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              {/* -------------- Барааны нэр ---------------- */}
              <CustomInput
                title={"Барааны нэр"}
                value={productName}
                onChange={setProductName}
                required={true}
              />
              <HStack w={"full"}>
                {/* -------------- Үнэ ---------------- */}
                <CustomInput
                  title={"Үнэ"}
                  value={price}
                  onChange={setPrice}
                  type={"number"}
                  required={true}
                />
                {/* -------------- Тоо ширхэг ---------------- */}
                <CustomInput
                  title={"Тоо ширхэг"}
                  value={stock}
                  onChange={setStock}
                  type={"number"}
                  required={true}
                />
              </HStack>
              <ImageUpload
                title={"Зураг"}
                setData={setImage}
                bgColor={"#F0F0F0"}
              />
              {image && (
                <VStack pos={"relative"}>
                  <Image
                    src={image}
                    objectFit={"contain"}
                    w={"full"}
                    h={"32"}
                  />
                  <Icon
                    as={IoClose}
                    pos={"absolute"}
                    w={"5"}
                    h={"5"}
                    top={0}
                    right={0}
                    color={"#ff0000"}
                    onClick={() => setImage("")}
                    cursor={"pointer"}
                  />
                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                clearData();
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={submitHandler}
              isDisabled={
                productName && price && stock && !isDisabled ? false : true
              }
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ListTable;
