import React, { useEffect, useState } from "react";
import {
  Divider,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
  Icon,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  StackDivider,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../utils/axios";
import { FiCheck, FiEdit, FiInfo } from "react-icons/fi";
import { BiTransfer } from "react-icons/bi";
import AskModal from "../../../components/modal/AskModal";
import { IoMdClose } from "react-icons/io";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { TbStatusChange } from "react-icons/tb";
import CustomTextArea from "../../../components/input/CustomTextArea";

const DriverDetail = () => {
  const [trigger, setTrigger] = useState(true);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [driverSalary, setDriverSalary] = useState(0);
  // const [countrySide, setCountrySide] = useState(0);
  const [tomorrowOrdersCount, setTomorrowOrdersCount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate() - 1).padStart(2, "0");
  const [date, setDate] = useState(`${year}-${month}-${day}`);
  const [orderId, setOrderId] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [cart, setCart] = useState("");
  const [cash, setCash] = useState("");
  const [editId, setEditId] = useState("");
  const [inBankAccount, setInBankAccount] = useState(0);
  const [inCash, setInCash] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [amount, setAmount] = useState("");
  const [countryEditId, setCountryEditId] = useState("");
  // ------------ notPaid ------------
  const [notPaidModal, setNotPaidModal] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [cancelDesc, setCancelDesc] = useState("");
  const [notPaidId, setNotPaidId] = useState(false);
  const location = useLocation();
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (trigger && location.state._id && date) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/driver/detail", {
          params: {
            driverId: location.state._id,
            date: date,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          // console.log("driver detail --------->", result.data);
          setData(result.data);
          setTotal(result.data.total);
          setDriverSalary(result.data.driverSalary);
          // setCountrySide(
          //   result?.data?.countrySide ? result.data.countrySide : 0
          // );
          setTomorrowOrdersCount(result.data.tomorrowOrdersCount);
          setInBankAccount(result.data.inBankAccount);
          setInCash(result.data.inCash);
          setIsLoading(false);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const submitHandler = () => {
    if (
      location.state._id &&
      date &&
      (cash || cash === 0) &&
      (cart || cart === 0) &&
      driverSalary &&
      total
    ) {
      setIsDisabled(true);
      axios
        .post(
          "/api/driver/detail",
          {
            driverId: location.state._id,
            date: date,
            inCash: parseInt(cash),
            inBankAccount: parseInt(cart),
            driverSalary: parseInt(driverSalary),
            total: parseInt(total),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setCash("");
            setCart("");
            setShowModal(false);
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const transferHandler = () => {
    if (transferDate && orderId) {
      setIsDisabled(true);
      axios
        .put(
          "/api/driver/detail",
          {
            orderId: orderId,
            date: transferDate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            onClose();
            setOrderId("");
            setTransferDate("");
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const editHandler = () => {
    if (
      editId &&
      (cash || cash === 0) &&
      (cart || cart === 0) &&
      driverSalary &&
      total
    ) {
      setIsDisabled(true);
      axios
        .put(
          "/api/driver/edit_record",
          {
            driverRecordId: editId,
            inCash: parseInt(cash),
            inBankAccount: parseInt(cart),
            driverSalary: parseInt(driverSalary),
            total: parseInt(total),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setEditId("");
            setCash("");
            setCart("");
            // setCountrySide("");
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const countryEditHandler = () => {
    if (countryEditId) {
      if (phoneNo && amount) {
        setIsDisabled(true);
        axios
          .put(
            "/api/driver/country_side",
            {
              _id: countryEditId,
              phoneNo: phoneNo,
              amount: parseInt(amount),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            if (result.data.success) {
              if (!toast.isActive(id)) {
                toast({
                  id,
                  duration: 2000,
                  position: "top",
                  status: "success",
                  description: "Амжилттай!",
                });
              }
              setCountryEditId("");
              setPhoneNo("");
              setAmount("");
              setCountryModal(false);
              setTrigger(true);
            }
            setIsDisabled(false);
          })
          .catch((err) => {
            // console.log(err);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: err.response.data.error
                  ? err.response.data.error
                  : "Алдаа гарлаа!",
              });
            }
            setIsDisabled(false);
          });
      }
    } else {
      if (location.state._id && date && phoneNo && amount) {
        setIsDisabled(true);
        axios
          .post(
            "/api/driver/country_side",
            {
              driverId: location.state._id,
              date: date,
              phoneNo: phoneNo,
              amount: parseInt(amount),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            if (result.data.success) {
              setPhoneNo("");
              setAmount("");
              setCountryModal(false);
              setTrigger(true);
            }
            setIsDisabled(false);
          })
          .catch((err) => {
            // console.log(err);
            setIsDisabled(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: err.response.data.error
                  ? err.response.data.error
                  : "Алдаа гарлаа!",
              });
            }
          });
      }
    }
  };

  const changeStatusHandler = () => {
    if (statusType === "cancelled" && cancelDesc && notPaidId) {
      setIsDisabled(true);
      axios
        .put(
          "/api/driver/set_cancelled",
          {
            orderId: notPaidId,
            description: cancelDesc,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setNotPaidModal(false);
            setNotPaidId("");
            setStatusType("");
            setPaymentType("");
            setCancelDesc("");
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
    if (
      statusType === "delivered" &&
      (paymentType === "cash" || paymentType === "inBank") &&
      notPaidId
    ) {
      setIsDisabled(true);
      axios
        .put(
          "/api/driver/set_delivered",
          {
            orderId: notPaidId,
            paymentType: paymentType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setNotPaidModal(false);
            setNotPaidId("");
            setStatusType("");
            setPaymentType("");
            setCancelDesc("");
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const income =
    parseInt(total) -
    parseInt(driverSalary) -
    parseInt(
      data?.countrySideRecords
        .map((e) => e.amount)
        .reduce((acc, curr) => acc + curr, 0)
    );

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <Stack
        direction={["column", "column", "row", "row", "row", "row"]}
        w={"full"}
        align={"center"}
        justify={"space-between"}
      >
        <Input
          placeholder={"-"}
          w={"40"}
          bg={"#f9f9f9"}
          fontWeight={"normal"}
          fontSize={14}
          color={"#000"}
          rounded={"none"}
          borderWidth={"thin"}
          borderColor={"#eee"}
          type="date"
          _focusVisible={{
            outline: "none",
            borderColor: "#F07F1F",
          }}
          _hover={{
            outline: "none",
          }}
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            setTrigger(true);
          }}
        />
        <HStack
          bg={"#f9f9f9"}
          minW={"60"}
          py={"2"}
          px={"4"}
          borderWidth={"thin"}
          borderColor={"#eee"}
          align={"center"}
          justify={"space-between"}
        >
          <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
            Маргаашийн захиалга:
          </Text>
          <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
            {tomorrowOrdersCount || tomorrowOrdersCount === 0
              ? tomorrowOrdersCount + "ш"
              : "-"}
          </Text>
        </HStack>
      </Stack>
      {isLoading ? (
        <Spinner color={"#F07F1F"} alignSelf={"center"} />
      ) : data ? (
        <Stack
          w={"full"}
          direction={["column", "column", "column", "column", "row", "row"]}
          spacing={4}
        >
          <VStack
            w={"full"}
            h={"max-content"}
            bg={"#f9f9f9"}
            align={"flex-start"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            py={"3"}
            px={"4"}
          >
            <HStack
              w={"full"}
              spacing={1}
              align={"flex-start"}
              justify={"space-between"}
              bg={"#fff"}
              py={"2"}
              px={"4"}
              borderWidth={"thin"}
              borderColor={"#eee"}
              borderLeftColor={"#F07F1F"}
              borderLeftWidth={"medium"}
            >
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                Захиалга
              </Text>
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                Нийт бараа:{" "}
                <Text
                  as={"span"}
                  color={"#000"}
                  fontSize={14}
                  fontWeight={"medium"}
                >
                  {data.totalProducts + " ш"}
                </Text>
              </Text>
            </HStack>
            <VStack w={"full"} align={"flex-start"} divider={<StackDivider />}>
              {data.theOrders?.length > 0 ? (
                data.theOrders
                  .sort((a, b) => a.orderIndex - b.orderIndex)
                  .map((e, i) =>
                    e.status !== "notPaid" ? (
                      <HStack
                        key={e._id}
                        w={"full"}
                        divider={<StackDivider />}
                        align={"center"}
                      >
                        <Text
                          w={"8"}
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                          textAlign={"center"}
                        >
                          {e.orderIndex + 1}
                        </Text>
                        <VStack w={"full"} align={"flex-start"} spacing={0}>
                          <Text
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"medium"}
                          >
                            Утас:{" "}
                            <Text
                              as={"span"}
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.phoneNo}
                            </Text>
                          </Text>
                          <Text
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"medium"}
                          >
                            Хаяг:{" "}
                            <Text
                              as={"span"}
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.address}
                            </Text>
                          </Text>
                          {e.products && e.products.length > 0 ? (
                            <VStack
                              w={"full"}
                              py={"1"}
                              px={"2"}
                              bg={"#fff"}
                              align={"flex-start"}
                              justify={"flex-start"}
                              borderWidth={"thin"}
                              borderColor={"#eee"}
                              spacing={0}
                            >
                              {e.products.map((prod, index) => (
                                <HStack key={index}>
                                  <Text
                                    color={"#000"}
                                    fontSize={14}
                                    fontWeight={"normal"}
                                  >
                                    {/* {index + 1 + ". "} */}
                                    {"-"}
                                  </Text>
                                  <Text
                                    color={"#000"}
                                    fontSize={14}
                                    fontWeight={"normal"}
                                  >
                                    {prod.product.name}
                                  </Text>
                                  <Text
                                    color={"#000"}
                                    fontSize={14}
                                    fontWeight={"normal"}
                                  >
                                    {/* {index + 1 + ". "} */}
                                    {"-"}
                                  </Text>
                                  <Text
                                    color={"#000"}
                                    fontSize={14}
                                    fontWeight={"normal"}
                                  >
                                    {prod.quantity + " ш"}
                                  </Text>
                                </HStack>
                              ))}
                            </VStack>
                          ) : null}
                          <Text
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"medium"}
                          >
                            Тайлбар:{" "}
                            <Text
                              as={"span"}
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.description ? e.description : "-"}
                            </Text>
                          </Text>
                          <Text
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"medium"}
                          >
                            Төлөв:{" "}
                            <Text
                              as={"span"}
                              color={
                                e.status === "pending"
                                  ? "#F07F1F"
                                  : e.status === "driverTaken"
                                  ? "#0042FF"
                                  : e.status === "delivered"
                                  ? "#45A735"
                                  : "#ff0000"
                              }
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.status
                                ? e.status === "pending"
                                  ? "Хүлээгдэж буй"
                                  : e.status === "notPaid"
                                  ? "Төлөгдөөгүй"
                                  : e.status === "driverTaken"
                                  ? "Жолооч авсан"
                                  : e.status === "delivered"
                                  ? "Хүргэгдсэн"
                                  : "Цуцлагдсан"
                                : "-"}
                            </Text>
                          </Text>
                          {e.status === "cancelled" ? (
                            <Text
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"medium"}
                            >
                              Цуцалсан тайлбар:{" "}
                              <Text
                                as={"span"}
                                color={"#000"}
                                fontSize={14}
                                fontWeight={"normal"}
                              >
                                {e.cancelDescription}
                              </Text>
                            </Text>
                          ) : null}
                        </VStack>
                        {e.status === "driverTaken" ? (
                          <Icon
                            as={BiTransfer}
                            w={"5"}
                            h={"5"}
                            ml={"2"}
                            color={"#F07F1F"}
                            onClick={() => {
                              setOrderId(e._id);
                              onOpen();
                            }}
                            cursor={"pointer"}
                          />
                        ) : null}
                      </HStack>
                    ) : null
                  )
              ) : (
                <VStack w={"full"} spacing={1} py={"2"}>
                  <Icon as={FiInfo} w={"5"} h={"5"} />
                  <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                    Мэдээлэл олдсонгүй
                  </Text>
                </VStack>
              )}
            </VStack>
            {/* ----------------------------------------------- notPaid status --------------------------------------------------- */}
            <HStack
              w={"full"}
              spacing={1}
              align={"flex-start"}
              justify={"space-between"}
              bg={"#fff"}
              py={"2"}
              px={"4"}
              borderWidth={"thin"}
              borderColor={"#eee"}
              borderLeftColor={"#F07F1F"}
              borderLeftWidth={"medium"}
            >
              <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                Төлөгдөөгүй захиалга
              </Text>
              {/* <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                Нийт бараа:{" "}
                <Text
                  as={"span"}
                  color={"#000"}
                  fontSize={14}
                  fontWeight={"medium"}
                >
                  {data.totalProducts + " ш"}
                </Text>
              </Text> */}
            </HStack>
            <VStack w={"full"} align={"flex-start"} divider={<StackDivider />}>
              {data.theOrders?.length > 0 ? (
                data.theOrders.map((e, i) =>
                  e.status === "notPaid" ? (
                    <HStack
                      key={e._id}
                      w={"full"}
                      divider={<StackDivider />}
                      align={"center"}
                    >
                      <Text
                        w={"8"}
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"medium"}
                        textAlign={"center"}
                      >
                        {i + 1}
                      </Text>
                      <VStack w={"full"} align={"flex-start"} spacing={0}>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Утас:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.phoneNo}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Хаяг:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.address}
                          </Text>
                        </Text>
                        {e.products && e.products.length > 0 ? (
                          <VStack
                            w={"full"}
                            py={"1"}
                            px={"2"}
                            bg={"#fff"}
                            align={"flex-start"}
                            justify={"flex-start"}
                            borderWidth={"thin"}
                            borderColor={"#eee"}
                            spacing={0}
                          >
                            {e.products.map((prod, index) => (
                              <HStack key={index}>
                                <Text
                                  color={"#000"}
                                  fontSize={14}
                                  fontWeight={"normal"}
                                >
                                  {/* {index + 1 + ". "} */}
                                  {"-"}
                                </Text>
                                <Text
                                  color={"#000"}
                                  fontSize={14}
                                  fontWeight={"normal"}
                                >
                                  {prod.product.name}
                                </Text>
                                <Text
                                  color={"#000"}
                                  fontSize={14}
                                  fontWeight={"normal"}
                                >
                                  {/* {index + 1 + ". "} */}
                                  {"-"}
                                </Text>
                                <Text
                                  color={"#000"}
                                  fontSize={14}
                                  fontWeight={"normal"}
                                >
                                  {prod.quantity + " ш"}
                                </Text>
                              </HStack>
                            ))}
                          </VStack>
                        ) : null}
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Тайлбар:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.description ? e.description : "-"}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Төлөв:{" "}
                          <Text
                            as={"span"}
                            color={
                              e.status === "pending"
                                ? "#F07F1F"
                                : e.status === "driverTaken"
                                ? "#0042FF"
                                : e.status === "delivered"
                                ? "#45A735"
                                : "#ff0000"
                            }
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.status
                              ? e.status === "pending"
                                ? "Хүлээгдэж буй"
                                : e.status === "notPaid"
                                ? "Төлөгдөөгүй"
                                : e.status === "driverTaken"
                                ? "Жолооч авсан"
                                : e.status === "delivered"
                                ? "Хүргэгдсэн"
                                : "Цуцлагдсан"
                              : "-"}
                          </Text>
                        </Text>
                        {e.status === "cancelled" ? (
                          <Text
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"medium"}
                          >
                            Цуцалсан тайлбар:{" "}
                            <Text
                              as={"span"}
                              color={"#000"}
                              fontSize={14}
                              fontWeight={"normal"}
                            >
                              {e.cancelDescription}
                            </Text>
                          </Text>
                        ) : null}
                      </VStack>
                      <Icon
                        as={TbStatusChange}
                        w={"5"}
                        h={"5"}
                        ml={"2"}
                        color={"#F07F1F"}
                        onClick={() => {
                          setNotPaidId(e._id);
                          setNotPaidModal(true);
                        }}
                        cursor={"pointer"}
                      />
                    </HStack>
                  ) : null
                )
              ) : (
                <VStack w={"full"} spacing={1} py={"2"}>
                  <Icon as={FiInfo} w={"5"} h={"5"} />
                  <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                    Мэдээлэл олдсонгүй
                  </Text>
                </VStack>
              )}
            </VStack>
          </VStack>
          <VStack w={"full"} align={"flex-start"} spacing={6}>
            <VStack
              w={"full"}
              h={"max-content"}
              bg={"#f9f9f9"}
              align={"flex-start"}
              borderWidth={"thin"}
              borderColor={"#eee"}
              py={"3"}
              px={"4"}
            >
              {/* ------------------------------------------------------- Хүргэгдсэн бараа ------------------------------------------------------------ */}
              <HStack
                w={"full"}
                spacing={1}
                align={"flex-start"}
                justify={"space-between"}
                bg={"#fff"}
                py={"2"}
                px={"4"}
                borderWidth={"thin"}
                borderColor={"#eee"}
                borderLeftColor={"#F07F1F"}
                borderLeftWidth={"medium"}
              >
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  Хүргэгдсэн бараа
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  Барааны тоо:{" "}
                  <Text
                    as={"span"}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {data.deliveredProductsCount + " ш"}
                  </Text>
                </Text>
              </HStack>

              <VStack
                w={"full"}
                align={"flex-start"}
                divider={<StackDivider />}
              >
                {data.deliveredProducts?.length > 0 ? (
                  data.deliveredProducts.map((e, i) => (
                    <HStack
                      key={e.productId}
                      w={"full"}
                      divider={<StackDivider />}
                      align={"center"}
                    >
                      <Text
                        w={"8"}
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"medium"}
                        textAlign={"center"}
                      >
                        {i + 1}
                      </Text>
                      <VStack w={"full"} align={"flex-start"} spacing={0}>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Барааны нэр:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.productName}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Тоо ширхэг:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.quantity + " ш"}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Нийт үнэ:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.allPrice.toLocaleString() + "₮"}
                          </Text>
                        </Text>
                      </VStack>
                    </HStack>
                  ))
                ) : (
                  <VStack w={"full"} spacing={1} py={"2"}>
                    <Icon as={FiInfo} w={"5"} h={"5"} />
                    <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй
                    </Text>
                  </VStack>
                )}
              </VStack>

              {/* ------------------------------------------------------- Хүргэгдээгүй бараа ------------------------------------------------------------ */}
              <HStack
                w={"full"}
                spacing={1}
                align={"flex-start"}
                justify={"space-between"}
                bg={"#fff"}
                py={"2"}
                px={"4"}
                borderWidth={"thin"}
                borderColor={"#eee"}
                borderLeftColor={"#F07F1F"}
                borderLeftWidth={"medium"}
              >
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  Хүргэгдээгүй бараа
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  Барааны тоо:{" "}
                  <Text
                    as={"span"}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {data.notDeliveredProductsCount + " ш"}
                  </Text>
                </Text>
              </HStack>

              <VStack
                w={"full"}
                align={"flex-start"}
                divider={<StackDivider />}
              >
                {data.notDeliveredProducts?.length > 0 ? (
                  data.notDeliveredProducts.map((e, i) => (
                    <HStack
                      key={e.productId}
                      w={"full"}
                      divider={<StackDivider />}
                      align={"center"}
                    >
                      <Text
                        w={"8"}
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"medium"}
                        textAlign={"center"}
                      >
                        {i + 1}
                      </Text>
                      <VStack w={"full"} align={"flex-start"} spacing={0}>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Барааны нэр:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.productName}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Тоо ширхэг:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.quantity + " ш"}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Нийт үнэ:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.allPrice.toLocaleString() + "₮"}
                          </Text>
                        </Text>
                      </VStack>
                    </HStack>
                  ))
                ) : (
                  <VStack w={"full"} spacing={1} py={"2"}>
                    <Icon as={FiInfo} w={"5"} h={"5"} />
                    <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй
                    </Text>
                  </VStack>
                )}
              </VStack>
              {/* ----------------------------------------------------- Үлдэгдэл бараа -------------------------------------------------------------- */}
              <HStack
                w={"full"}
                spacing={1}
                align={"flex-start"}
                justify={"space-between"}
                bg={"#fff"}
                py={"2"}
                px={"4"}
                borderWidth={"thin"}
                borderColor={"#eee"}
                borderLeftColor={"#F07F1F"}
                borderLeftWidth={"medium"}
              >
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  Үлдэгдэл бараа
                </Text>
                <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
                  Барааны тоо:{" "}
                  <Text
                    as={"span"}
                    color={"#000"}
                    fontSize={14}
                    fontWeight={"medium"}
                  >
                    {data.notPaidProductsCount + " ш"}
                  </Text>
                </Text>
              </HStack>

              <VStack
                w={"full"}
                align={"flex-start"}
                divider={<StackDivider />}
              >
                {data.notPaidProducts?.length > 0 ? (
                  data.notPaidProducts.map((e, i) => (
                    <HStack
                      key={e.productId}
                      w={"full"}
                      divider={<StackDivider />}
                      align={"center"}
                    >
                      <Text
                        w={"8"}
                        color={"#000"}
                        fontSize={14}
                        fontWeight={"medium"}
                        textAlign={"center"}
                      >
                        {i + 1}
                      </Text>
                      <VStack w={"full"} align={"flex-start"} spacing={0}>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Барааны нэр:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.productName}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Тоо ширхэг:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.quantity + " ш"}
                          </Text>
                        </Text>
                        <Text
                          color={"#000"}
                          fontSize={14}
                          fontWeight={"medium"}
                        >
                          Нийт үнэ:{" "}
                          <Text
                            as={"span"}
                            color={"#000"}
                            fontSize={14}
                            fontWeight={"normal"}
                          >
                            {e.allPrice.toLocaleString() + "₮"}
                          </Text>
                        </Text>
                      </VStack>
                    </HStack>
                  ))
                ) : (
                  <VStack w={"full"} spacing={1} py={"2"}>
                    <Icon as={FiInfo} w={"5"} h={"5"} />
                    <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
                      Мэдээлэл олдсонгүй
                    </Text>
                  </VStack>
                )}
              </VStack>
            </VStack>

            <VStack alignSelf={"flex-end"} w={"max-content"} align={"flex-end"}>
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Нийт бараа:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {data.totalProducts + " ш"}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Хүргэгдсэн бараа:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {data.deliveredProductsCount + " ш"}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Хүргэгдээгүй бараа:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {data.notDeliveredProductsCount + " ш"}
                  </Text>
                </Box>
              </HStack>
              <Divider />
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Нийт дүн:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {total.toLocaleString() + "₮"}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Цалин:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {driverSalary.toLocaleString() + "₮"}
                  </Text>
                </Box>
              </HStack>

              <Divider />
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Орон нутаг:
                </Text>
                <VStack spacing={1}>
                  {data?.countrySideRecords.length > 0 ? (
                    data.countrySideRecords.map((e, i) => (
                      <HStack
                        key={i}
                        bg={"#f9f9f9"}
                        minW={"60"}
                        py={"1"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        align={"center"}
                        justify={"space-between"}
                      >
                        <VStack w={"full"} spacing={0} align={"flex-start"}>
                          <Text
                            fontSize={14}
                            color={"#000"}
                            fontWeight={"medium"}
                          >
                            {e.phoneNo}
                          </Text>
                          <Text
                            fontSize={14}
                            color={"#000"}
                            fontWeight={"medium"}
                          >
                            {e.amount.toLocaleString() + "₮"}
                          </Text>
                        </VStack>
                        <Icon
                          as={FiEdit}
                          w={"4"}
                          h={"4"}
                          color={"#F07F1F"}
                          onClick={() => {
                            setCountryEditId(e._id);
                            setPhoneNo(e.phoneNo);
                            setAmount(e.amount);
                            setCountryModal(true);
                          }}
                          cursor={"pointer"}
                        />
                      </HStack>
                    ))
                  ) : (
                    <Box
                      bg={"#f9f9f9"}
                      minW={"60"}
                      py={"2"}
                      px={"4"}
                      borderWidth={"thin"}
                      borderColor={"#eee"}
                      textAlign={"end"}
                    >
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        {data.countrySideRecords.length + "ш"}
                      </Text>
                    </Box>
                  )}
                  <Button
                    colorScheme={"orange"}
                    w={"60"}
                    h={"8"}
                    px={"4"}
                    borderWidth={"thin"}
                    borderColor={"#eee"}
                    rounded={"none"}
                    fontSize={14}
                    color={"#fff"}
                    fontWeight={"medium"}
                    onClick={() => setCountryModal(true)}
                    isDisabled={isDisabled}
                  >
                    Орон нутаг нэмэх
                  </Button>
                </VStack>
              </HStack>
              <HStack
                bg={"#f9f9f9"}
                minW={"60"}
                py={"1"}
                px={"4"}
                borderWidth={"thin"}
                borderColor={"#eee"}
                align={"center"}
                justify={"space-between"}
              >
                <VStack w={"full"} spacing={0} align={"flex-start"}>
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    Нийт дүн:
                  </Text>
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {data?.countrySideRecords
                      .map((e) => e.amount)
                      .reduce((acc, curr) => acc + curr, 0) + "₮"}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Орлого:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {/* {(
                      parseInt(total) -
                      parseInt(driverSalary) -
                      parseInt(
                        data?.countrySideRecords
                          .map((e) => e.amount)
                          .reduce((acc, curr) => acc + curr, 0)
                      )
                    ).toLocaleString() + "₮"} */}
                    {income.toLocaleString() + "₮"}
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Үлдэгдэл:
                </Text>
                <Box
                  bg={"#f9f9f9"}
                  minW={"60"}
                  py={"2"}
                  px={"4"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  textAlign={"end"}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    {data?.notPaidProducts.length > 0
                      ? parseInt(
                          data.notPaidProducts
                            .map((e) => e.allPrice)
                            .reduce((acc, curr) => acc + curr, 0)
                        ).toLocaleString() + "₮"
                      : "-"}
                  </Text>
                </Box>
              </HStack>

              {data.recordWritten ? (
                editId ? (
                  <VStack align={"flex-end"} mt={"2"}>
                    <Divider />
                    <HStack>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        Бэлэн:
                      </Text>
                      <Input
                        placeholder="-"
                        bg={"#f9f9f9"}
                        w={"60"}
                        rounded={"none"}
                        py={"2"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        textAlign={"end"}
                        fontWeight={"normal"}
                        fontSize={14}
                        color={"#000"}
                        type="number"
                        value={cash}
                        onChange={(e) => setCash(e.target.value)}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#F07F1F",
                        }}
                        _hover={{
                          outline: "none",
                        }}
                      />
                    </HStack>
                    <HStack>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        Данс:
                      </Text>
                      <Input
                        placeholder="-"
                        bg={"#f9f9f9"}
                        w={"60"}
                        rounded={"none"}
                        py={"2"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        textAlign={"end"}
                        fontWeight={"normal"}
                        fontSize={14}
                        color={"#000"}
                        type="number"
                        value={cart}
                        onChange={(e) => setCart(e.target.value)}
                        _focusVisible={{
                          outline: "none",
                          borderColor: "#F07F1F",
                        }}
                        _hover={{
                          outline: "none",
                        }}
                      />
                    </HStack>
                    <HStack>
                      <Button
                        colorScheme={"gray"}
                        w={"28"}
                        py={"2"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        rounded={"none"}
                        fontSize={14}
                        color={"#000"}
                        fontWeight={"medium"}
                        onClick={() => setEditId("")}
                      >
                        Болих
                      </Button>
                      <Button
                        colorScheme={"orange"}
                        w={"28"}
                        py={"2"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        rounded={"none"}
                        fontSize={14}
                        color={"#fff"}
                        fontWeight={"medium"}
                        onClick={() => editHandler()}
                        isDisabled={
                          editId &&
                          cash &&
                          cart &&
                          driverSalary &&
                          total &&
                          !isDisabled
                            ? false
                            : true
                        }
                      >
                        Хадгалах
                      </Button>
                    </HStack>
                  </VStack>
                ) : (
                  <VStack align={"flex-end"} mt={"2"}>
                    <Divider />
                    <HStack>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        Бэлэн:
                      </Text>
                      <Box
                        bg={"#f9f9f9"}
                        minW={"60"}
                        py={"2"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        textAlign={"end"}
                      >
                        <Text
                          fontSize={14}
                          color={"#000"}
                          fontWeight={"medium"}
                        >
                          {inCash || inCash === 0
                            ? inCash.toLocaleString() + "₮"
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        Данс:
                      </Text>
                      <Box
                        bg={"#f9f9f9"}
                        minW={"60"}
                        py={"2"}
                        px={"4"}
                        borderWidth={"thin"}
                        borderColor={"#eee"}
                        textAlign={"end"}
                      >
                        <Text
                          fontSize={14}
                          color={"#000"}
                          fontWeight={"medium"}
                        >
                          {inBankAccount || inBankAccount === 0
                            ? inBankAccount.toLocaleString() + "₮"
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Text fontSize={14} color={"#45A735"} fontWeight={"bold"}>
                        Тооцоо хийгдсэн
                      </Text>
                      <IconButton
                        colorScheme="orange"
                        size={"sm"}
                        icon={<FiEdit />}
                        onClick={() => {
                          setEditId(data._id);
                          setCash(inCash.toString());
                          setCart(inBankAccount.toString());
                        }}
                      />
                    </HStack>
                  </VStack>
                )
              ) : (
                <VStack align={"flex-end"} mt={"2"}>
                  <Divider />

                  <HStack>
                    <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                      Бэлэн:
                    </Text>
                    <Input
                      placeholder="-"
                      bg={"#f9f9f9"}
                      w={"60"}
                      rounded={"none"}
                      py={"2"}
                      px={"4"}
                      borderWidth={"thin"}
                      borderColor={"#eee"}
                      textAlign={"end"}
                      fontWeight={"normal"}
                      fontSize={14}
                      color={"#000"}
                      type="number"
                      value={cash}
                      onChange={(e) => {
                        setCash(e.target.value);
                        setCart(parseInt(income - e.target.value));
                      }}
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#F07F1F",
                      }}
                      _hover={{
                        outline: "none",
                      }}
                    />
                  </HStack>
                  <HStack>
                    <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                      Данс:
                    </Text>
                    <Input
                      placeholder="-"
                      bg={"#f9f9f9"}
                      w={"60"}
                      rounded={"none"}
                      py={"2"}
                      px={"4"}
                      borderWidth={"thin"}
                      borderColor={"#eee"}
                      textAlign={"end"}
                      fontWeight={"normal"}
                      fontSize={14}
                      color={"#000"}
                      type="number"
                      value={cart}
                      onChange={(e) => {
                        setCart(e.target.value);
                        setCash(parseInt(income - e.target.value));
                      }}
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#F07F1F",
                      }}
                      _hover={{
                        outline: "none",
                      }}
                    />
                  </HStack>
                  <Button
                    colorScheme={"orange"}
                    w={"60"}
                    py={"2"}
                    px={"4"}
                    borderWidth={"thin"}
                    borderColor={"#eee"}
                    rounded={"none"}
                    fontSize={14}
                    color={"#fff"}
                    fontWeight={"medium"}
                    onClick={() => setShowModal(true)}
                    isDisabled={
                      location.state._id &&
                      date &&
                      cash &&
                      cart &&
                      driverSalary &&
                      total &&
                      !isDisabled
                        ? false
                        : true
                    }
                  >
                    Хадгалах
                  </Button>
                </VStack>
              )}
            </VStack>
          </VStack>
        </Stack>
      ) : (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      {/* -------------------------- Confirm ---------------------------- */}
      <AskModal
        showModal={showModal}
        setShowModal={setShowModal}
        submitHandler={submitHandler}
        isDisable={
          location.state._id &&
          date &&
          cash &&
          cart &&
          driverSalary &&
          total &&
          !isDisabled
            ? false
            : true
        }
      />

      {/* -------------------------- transfer order ---------------------------- */}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Хүргэлтийн өдөр солих</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              <Input
                placeholder={"-"}
                w={"full"}
                bg={"#f9f9f9"}
                fontWeight={"normal"}
                fontSize={14}
                color={"#000"}
                rounded={"none"}
                borderWidth={"thin"}
                borderColor={"#eee"}
                type="date"
                _focusVisible={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
                _hover={{
                  outline: "none",
                }}
                value={transferDate}
                onChange={(e) => setTransferDate(e.target.value)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setTransferDate("");
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={transferHandler}
              isDisabled={orderId && transferDate && !isDisabled ? false : true}
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* -------------------------- Country ---------------------------- */}
      <Modal
        isCentered
        onClose={!countryModal}
        isOpen={countryModal}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Орон нутаг</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={0}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  <Text as={"span"} color={"#ff0000"}>
                    *
                  </Text>
                  Утасны дугаар:
                </Text>
                <Input
                  placeholder={"-"}
                  w={"full"}
                  bg={"#f9f9f9"}
                  fontWeight={"normal"}
                  fontSize={14}
                  color={"#000"}
                  rounded={"none"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  type="number"
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#F07F1F",
                  }}
                  _hover={{
                    outline: "none",
                  }}
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </VStack>
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-start"}
                spacing={0}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  <Text as={"span"} color={"#ff0000"}>
                    *
                  </Text>
                  Үнэ:
                </Text>
                <Input
                  placeholder={"-"}
                  w={"full"}
                  bg={"#f9f9f9"}
                  fontWeight={"normal"}
                  fontSize={14}
                  color={"#000"}
                  rounded={"none"}
                  borderWidth={"thin"}
                  borderColor={"#eee"}
                  type="number"
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#F07F1F",
                  }}
                  _hover={{
                    outline: "none",
                  }}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                setCountryModal(false);
                setPhoneNo("");
                setAmount("");
                setCountryEditId("");
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={countryEditHandler}
              isDisabled={phoneNo && amount && !isDisabled ? false : true}
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* -------------------------- Not Paid ---------------------------- */}
      <Modal
        isCentered
        onClose={!notPaidModal}
        isOpen={notPaidModal}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Төлөвийг солих</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            {statusType === "cancelled" ? (
              <CustomTextArea
                title={"Тайлбар"}
                value={cancelDesc}
                onChange={setCancelDesc}
                required={true}
                onKeyDown={(e) => {
                  if (cancelDesc && !isDisabled) {
                    if (e.key === "Enter") {
                      changeStatusHandler();
                    }
                  }
                }}
              />
            ) : statusType === "delivered" ? (
              <VStack w={"full"}>
                <HStack
                  transition={"ease-in-out .2s"}
                  w={"full"}
                  h={"10"}
                  bg={"#ddd"}
                  rounded={"md"}
                  px={"4"}
                  align={"center"}
                  justify={"flex-start"}
                  color={paymentType === "cash" ? "#F07F1F" : "#000"}
                  borderWidth={"thin"}
                  borderColor={
                    paymentType === "cash" ? "#F07F1F" : "transparent"
                  }
                  onClick={() => setPaymentType("cash")}
                  cursor={"pointer"}
                >
                  <Icon
                    as={
                      paymentType === "cash"
                        ? MdOutlineCheckBox
                        : MdOutlineCheckBoxOutlineBlank
                    }
                    w={5}
                    h={5}
                  />
                  <Text fontSize={14} fontWeight={"medium"}>
                    Бэлэн
                  </Text>
                </HStack>
                <HStack
                  transition={"ease-in-out .2s"}
                  w={"full"}
                  h={"10"}
                  bg={"#ddd"}
                  rounded={"md"}
                  px={"4"}
                  align={"center"}
                  justify={"flex-start"}
                  color={paymentType === "inBank" ? "#F07F1F" : "#000"}
                  borderWidth={"thin"}
                  borderColor={
                    paymentType === "inBank" ? "#F07F1F" : "transparent"
                  }
                  onClick={() => setPaymentType("inBank")}
                  cursor={"pointer"}
                >
                  <Icon
                    as={
                      paymentType === "inBank"
                        ? MdOutlineCheckBox
                        : MdOutlineCheckBoxOutlineBlank
                    }
                    w={5}
                    h={5}
                  />
                  <Text fontSize={14} fontWeight={"medium"}>
                    Банк
                  </Text>
                </HStack>
              </VStack>
            ) : (
              <HStack w={"full"}>
                <VStack
                  w={"full"}
                  h={20}
                  align={"center"}
                  justify={"center"}
                  bg={"#F07F1F"}
                  rounded={"md"}
                  spacing={1}
                  color={"#fff"}
                  onClick={() => setStatusType("delivered")}
                  cursor={"pointer"}
                  _hover={{ transform: "scale(1.05)" }}
                  transition="transform 0.2s"
                >
                  <Icon as={FiCheck} w={5} h={5} />
                  <Text fontWeight={"medium"} fontSize={14}>
                    Хүргэгдсэн
                  </Text>
                </VStack>
                <VStack
                  w={"full"}
                  h={20}
                  align={"center"}
                  justify={"center"}
                  bg={"#ddd"}
                  rounded={"md"}
                  spacing={1}
                  color={"#000"}
                  onClick={() => setStatusType("cancelled")}
                  cursor={"pointer"}
                  _hover={{ transform: "scale(1.05)" }}
                  transition="transform 0.2s"
                >
                  <Icon as={IoMdClose} w={5} h={5} />
                  <Text fontWeight={"medium"} fontSize={14}>
                    Цуцлах
                  </Text>
                </VStack>
              </HStack>
            )}
            <Divider mt={4} />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                setNotPaidModal(false);
                setNotPaidId("");
                setStatusType("");
                setPaymentType("");
                setCancelDesc("");
              }}
            >
              Болих
            </Button>
            {statusType === "cancelled" || statusType === "delivered" ? (
              <Button
                w={"full"}
                h={"9"}
                bg={"#F07F1F"}
                color={"#fff"}
                rounded={"md"}
                ml={3}
                fontSize={14}
                fontWeight={"medium"}
                px={"8"}
                _focusVisible={{
                  outline: "none",
                }}
                _hover={{
                  bg: "#F28B33",
                }}
                onClick={changeStatusHandler}
                isDisabled={
                  ((statusType === "cancelled" && cancelDesc) ||
                    (statusType === "delivered" && paymentType)) &&
                  !isDisabled
                    ? false
                    : true
                }
                isLoading={isDisabled}
                loadingText={"Уншиж байна..."}
              >
                Хадгалах
              </Button>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default DriverDetail;
