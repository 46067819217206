import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Icon,
  Text,
} from "@chakra-ui/react";
import { navData } from "./SidebarData";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const SideBarMobile = ({ showSideBar, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      onClose={toggleSidebar}
      isOpen={showSideBar}
      size={"sm"}
      placement={"left"}
    >
      <DrawerOverlay />
      <DrawerContent bg={"#F9F9F9"}>
        <DrawerCloseButton _focusVisible={{ outline: "none" }} />
        <DrawerBody pt={"10"}>
          <VStack align={"center"} justify={"space-between"} h={"full"}>
            <VStack w={"full"} spacing={0}>
              {navData.map((data, index) => (
                <HStack
                  transition={"ease-in-out .1s"}
                  key={index}
                  w={"full"}
                  h={"12"}
                  bg={
                    (location.pathname === "/order" && data.toLink === "/") ||
                    location.pathname.split("/")[1] ===
                      data.toLink.split("/")[1]
                      ? "#fff"
                      : "transparent"
                  }
                  pl={"6"}
                  pr={"4"}
                  borderRightWidth={"4px"}
                  borderRightColor={
                    (location.pathname === "/order" && data.toLink === "/") ||
                    location.pathname.split("/")[1] ===
                      data.toLink.split("/")[1]
                      ? "#F07F1F"
                      : "transparent"
                  }
                  onClick={() => {
                    navigate(data.toLink);
                    toggleSidebar();
                  }}
                  cursor={"pointer"}
                  _hover={{
                    borderColor: "#F07F1F",
                    bg: "#fff",
                  }}
                >
                  <Icon as={data.icon} />
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    {data.title}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideBarMobile;
