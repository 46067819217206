import React, { createContext, useContext } from "react";
import { useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../axios";
import { jwtDecode } from "jwt-decode";
import UserContext from "../context/UserContext";
const AuthContext = createContext();

export const Authentication = (props) => {
  const {
    setIsLoading,
    setBtnSpinner,
    setIsLoggedIn,
    setAccessToken,
    setRefreshToken,
    setUsername,
  } = useContext(UserContext);
  const toast = useToast();
  const id = "toast";

  const loginSuccess = (accToken, refToken, user) => {
    Cookies.set("accessToken", accToken);
    Cookies.set("refreshToken", refToken);
    Cookies.set("username", user);
    Cookies.set("isLoggedIn", true);
    setAccessToken(accToken);
    setRefreshToken(refToken);
    setUsername(user);
    setIsLoggedIn(true);
  };

  const loginHandler = (user, pass) => {
    setIsLoading(true);
    setBtnSpinner(true);
    axios
      .post("/auth/login", {
        username: user,
        password: pass,
      })
      .then((result) => {
        // console.log("login result -->", result.data);
        const decodeData = jwtDecode(result.data.accessToken);
        // console.log("jwt", decodeData);
        loginSuccess(
          result.data.accessToken,
          result.data.refreshToken,
          decodeData.username
        );
        setIsLoading(false);
        setBtnSpinner(false);
      })
      .catch((err) => {
        // console.log("login err -->", err.response);
        setIsLoading(false);
        setBtnSpinner(false);
        if (!toast.isActive(id)) {
          toast({
            id,
            duration: 2000,
            position: "top",
            status: "error",
            description:
              err?.response?.status === 400
                ? "Нэвтрэх нэр эсвэл нууц үг буруу байна!"
                : err?.response?.status === 404
                ? "Бүртгэлгүй хэрэглэгч байна!"
                : "Алдаа гарлаа!",
          });
        }
      });
  };

  const removeCookies = () => {
    Cookies.remove("username");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
  };

  const logoutHandler = () => {
    removeCookies();
    setIsLoggedIn(false);
    setAccessToken("");
    setRefreshToken("");
    setUsername("");
    // setIsLoading(true);
    // const refToken = Cookies.get("refreshToken");
    // axios
    //   .post("/auth/logout", {
    //     refreshToken: refToken,
    //   })
    //   .then((result) => {
    //     // console.log(result.data);
    //     if (result.data.success) {
    //       removeCookies();
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     // console.log(err);
    //   });
  };

  return (
    <AuthContext.Provider
      value={{
        loginHandler,
        logoutHandler,
        removeCookies,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
