import React, { useContext, useState } from "react";
import {
  Center,
  VStack,
  Text,
  Input,
  InputRightElement,
  Icon,
  InputGroup,
} from "@chakra-ui/react";
import GradiantBtn from "../../components/btn/GradiantBtn";
import AuthContext from "../../utils/authRequest/Authentication";
import UserContext from "../../utils/context/UserContext";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const { loginHandler } = useContext(AuthContext);
  const { isLoading } = useContext(UserContext);
  // ----------------------------
  //       username: test
  //       password: test123$
  // ----------------------------

  const token = Cookies.get("accessToken");

  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <Center
      w={"full"}
      h={"full"}
      bgGradient={"linear(to-l, #F07F1F, #F95077)"}
      minH={"100vh"}
    >
      <VStack
        w={"sm"}
        bg={"#fff"}
        rounded={"sm"}
        px={"10"}
        py={"12"}
        spacing={4}
      >
        <Text
          fontSize={19}
          fontWeight={"semibold"}
          textTransform={"uppercase"}
          color={"#000"}
        >
          Нэвтрэх
        </Text>
        <VStack w={"full"}>
          <VStack
            spacing={0}
            w={"full"}
            align={"flex-start"}
            justify={"flex-start"}
          >
            <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
              Хэрэглэгчийн нэр:
            </Text>
            <Input
              autoFocus={true}
              w={"full"}
              bg={"#FAFBFD"}
              borderWidth={"thin"}
              borderColor={"#E3E3E3"}
              fontSize={15}
              fontWeight={"normal"}
              color={"#000"}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              _focusVisible={{
                outline: "none",
              }}
            />
          </VStack>
          <VStack
            spacing={0}
            w={"full"}
            align={"flex-start"}
            justify={"flex-start"}
          >
            <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
              Нууц үг:
            </Text>
            <InputGroup>
              <Input
                w={"full"}
                bg={"#FAFBFD"}
                borderWidth={"thin"}
                borderColor={"#E3E3E3"}
                fontSize={15}
                fontWeight={"normal"}
                color={"#000"}
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (username && password) {
                    if (e.key === "Enter") {
                      loginHandler(username, password);
                    }
                  }
                }}
                _focusVisible={{
                  outline: "none",
                }}
              />
              <InputRightElement
                children={
                  <Icon
                    as={show ? AiOutlineEye : AiOutlineEyeInvisible}
                    w={5}
                    h={5}
                    color={"gray.500"}
                    onClick={() => setShow(!show)}
                    cursor={"pointer"}
                  />
                }
              />
            </InputGroup>
          </VStack>
        </VStack>
        <GradiantBtn
          title={"Нэвтрэх"}
          onClick={() => loginHandler(username, password)}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
      </VStack>
    </Center>
  );
};

export default Login;
