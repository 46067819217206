import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Icon,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../utils/axios";
import ListTable from "./ListTable";
import GradiantBtn from "../../components/btn/GradiantBtn";
import CustomInput from "../../components/input/CustomInput";
import { FiMinusCircle, FiPlus } from "react-icons/fi";

const Zone = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  //
  const [areaName, setAreaName] = useState("");
  const [subAreas, setSubAreas] = useState([""]);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearData = () => {
    setAreaName("");
    setSubAreas([]);
  };

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/area/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("zone list --------->", result.data);
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const submitHandler = () => {
    if (areaName && subAreas.length > 0) {
      setIsDisabled(true);
      axios
        .post(
          "/api/area/",
          {
            areaName: areaName,
            subAreas: subAreas,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            clearData();
            onClose();
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const incHandler = () => {
    setSubAreas((prevState) => {
      return [...prevState, ""];
    });
  };

  const subAreasHandler = (value, i) => {
    const clone = [...subAreas];
    clone[i] = value;
    setSubAreas(clone);
  };

  const removeHandler = (i) => {
    setSubAreas(
      subAreas.filter((e, index) => {
        return index != i;
      })
    );
  };

  const headerData = ["#", "Дүүрэг", "Чиглэл", "Үйлдэл"];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack alignSelf={"flex-end"}>
        <GradiantBtn
          title={"Бүс нэмэх"}
          onClick={() => onOpen()}
          isDisabled={isDisabled}
        />
      </HStack>
      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Бүс нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"} spacing={2}>
              {/* -------------- Дүүрэг ---------------- */}
              <CustomInput
                title={"Дүүрэг"}
                value={areaName}
                onChange={setAreaName}
                required={true}
              />
              {/* -------------- Чиглэл ---------------- */}
              {subAreas.map((area, i) => (
                <VStack
                  key={i}
                  w={"full"}
                  align={"flex-start"}
                  justify={"flex-start"}
                  spacing={0}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    <Text as={"span"} color={"#ff0000"}>
                      *{" "}
                    </Text>
                    Чиглэл{"-" + (i + 1)}
                  </Text>
                  <InputGroup>
                    <Input
                      placeholder="-"
                      w={"full"}
                      bg={"#F0F0F0"}
                      fontWeight={"normal"}
                      fontSize={14}
                      color={"#000"}
                      _focusVisible={{
                        outline: "none",
                        borderColor: "#F07F1F",
                      }}
                      _hover={{
                        outline: "none",
                      }}
                      value={area}
                      onChange={(a) => subAreasHandler(a.target.value, i)}
                    />
                    {subAreas.length > 1 ? (
                      <InputRightElement>
                        <Icon
                          as={FiMinusCircle}
                          color={"#ff0000"}
                          onClick={() => removeHandler(i)}
                          cursor={"pointer"}
                        />
                      </InputRightElement>
                    ) : null}
                  </InputGroup>
                </VStack>
              ))}
              <Button
                w={"full"}
                h={"9"}
                colorScheme="gray"
                rounded={"md"}
                fontSize={14}
                fontWeight={"medium"}
                _focusVisible={{
                  outline: "none",
                }}
                onClick={incHandler}
              >
                Чиглэл нэмэх
              </Button>
            </VStack>
            <Divider mt={"6"} />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                clearData();
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={submitHandler}
              isDisabled={
                areaName && subAreas.length > 0 && !isDisabled ? false : true
              }
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Zone;
