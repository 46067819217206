import React, { useState } from "react";
import {
  Center,
  Checkbox,
  Divider,
  HStack,
  Text,
  VStack,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import GradiantBtn from "../../components/btn/GradiantBtn";
import CustomInput from "../../components/input/CustomInput";
import CustomTextArea from "../../components/input/CustomTextArea";
import PasswordInput from "../../components/input/PasswordInput";
import Cookies from "js-cookie";
import { useEffect } from "react";
import axios from "../../utils/axios";

const Profile = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [info, setInfo] = useState({});
  //
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState(null);
  //
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/personal/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("info --------->", result.data);
          const data = result.data.data;
          setInfo(data);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setPhoneNo(data.phoneNo);
          setUsername(data.username);
          setAddress(data.address);
          setGender(data.sex);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const infoHandler = () => {
    if (lastName && firstName && username && phoneNo && address && gender) {
      setIsDisabled(true);
      axios
        .put(
          "/api/personal/",
          {
            username: username,
            firstName: firstName,
            lastName: lastName,
            sex: gender,
            phoneNo: phoneNo,
            address: address,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const passwordHandler = () => {
    if (oldPassword && password) {
      setIsDisabled(true);
      axios
        .put(
          "/api/personal/password",
          {
            oldPassword: oldPassword,
            newPassword: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            onClose();
            setOldPassword("");
            setPassword("");
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      {isLoading ? (
        <Spinner color="orange" alignSelf={"center"} />
      ) : (
        <VStack
          w={["full", "full", "3xl", "3xl", "3xl", "3xl"]}
          bg={"#F9F9F9"}
          rounded={"sm"}
          align={"flex-start"}
          justify={"flex-start"}
          px={["6", "6", "8", "8", "10", "10"]}
          py={["4", "4", "6", "6", "8", "8"]}
          spacing={4}
        >
          <Text fontSize={17} fontWeight={"medium"} color={"#000"}>
            Тохиргоо
          </Text>
          <Stack
            direction={["column", "column", "row", "row", "row", "row"]}
            w={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={8}
          >
            <VStack w={"full"}>
              {/* -------------- Овог ---------------- */}
              <CustomInput
                title={"Овог"}
                value={lastName}
                onChange={setLastName}
                required={true}
                bgColor={"#fff"}
              />
              {/* -------------- Нэр ---------------- */}
              <CustomInput
                title={"Нэр"}
                value={firstName}
                onChange={setFirstName}
                required={true}
                bgColor={"#fff"}
              />
              {/* -------------- Утас ---------------- */}
              <CustomInput
                title={"Утас"}
                value={phoneNo}
                onChange={setPhoneNo}
                type={"number"}
                required={true}
                bgColor={"#fff"}
              />
              {/* -------------- Нэвтрэх нэр ---------------- */}
              <CustomInput
                title={"Нэвтрэх нэр"}
                value={username}
                onChange={setUsername}
                required={true}
                bgColor={"#fff"}
              />
              {/* -------------- Хаяг ---------------- */}
              <CustomTextArea
                title={"Хаяг"}
                value={address}
                onChange={setAddress}
                required={true}
                bgColor={"#fff"}
              />
            </VStack>
            <VStack w={"full"}>
              {/* -------------- Хүйс ---------------- */}
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-end"}
                spacing={0}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  <Text as={"span"} color={"#ff0000"}>
                    *
                  </Text>{" "}
                  Хүйс:
                </Text>
                <HStack w={"full"} h={"10"} spacing={6}>
                  <Checkbox
                    colorScheme="orange"
                    size={"md"}
                    value={gender}
                    onChange={() => setGender("male")}
                    isChecked={gender === "male" ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эрэгтэй
                    </Text>
                  </Checkbox>
                  <Checkbox
                    colorScheme="orange"
                    size={"md"}
                    value={gender}
                    onChange={() => setGender("female")}
                    isChecked={gender === "female" ? true : false}
                    css={`
                      > span:first-of-type {
                        box-shadow: unset;
                      }
                    `}
                  >
                    <Text fontSize={14} color="#000" fontWeight={"normal"}>
                      Эмэгтэй
                    </Text>
                  </Checkbox>
                </HStack>
              </VStack>
              {/* -------------- Нууц үг ---------------- */}
              <VStack
                w={"full"}
                align={"flex-start"}
                justify={"flex-end"}
                spacing={0}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  <Text as={"span"} color={"#ff0000"}>
                    *
                  </Text>{" "}
                  Нууц үг:
                </Text>
                <Center
                  transition={"ease-in-out .3s"}
                  w={"full"}
                  bg={"gray.200"}
                  h={"10"}
                  rounded={"md"}
                  cursor={"pointer"}
                  _hover={{
                    bg: "#eee",
                  }}
                  onClick={onOpen}
                >
                  <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                    Нууц үг солих
                  </Text>
                </Center>
              </VStack>
              <Center
                w={"44"}
                h={"44"}
                rounded={"full"}
                bg={"#fff"}
                mt={"10"}
              />
            </VStack>
          </Stack>
          <Divider />
          <HStack w={"60"} alignSelf={"flex-end"}>
            <GradiantBtn
              title={"Хадгалах"}
              onClick={() => infoHandler()}
              isLoading={isDisabled}
              loadingText={"Уншиж байна"}
              isDisabled={
                (info.firstName !== firstName ||
                  info.lastName !== lastName ||
                  info.username !== username ||
                  info.phoneNo !== phoneNo ||
                  info.address !== address ||
                  info.sex !== gender) &&
                lastName &&
                firstName &&
                username &&
                phoneNo &&
                address &&
                gender &&
                !isDisabled
                  ? false
                  : true
              }
            />
          </HStack>
        </VStack>
      )}

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Нууц үг солих</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              <PasswordInput
                title={"Хуучин нууц үг"}
                value={oldPassword}
                onChange={setOldPassword}
                required={true}
              />
              <PasswordInput
                title={"Шинэ нууц үг"}
                value={password}
                onChange={setPassword}
                required={true}
                onKeyDown={(e) => {
                  if (password && oldPassword) {
                    if (e.key === "Enter") {
                      passwordHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setOldPassword("");
                setPassword("");
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={passwordHandler}
              isDisabled={oldPassword && password && !isDisabled ? false : true}
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Profile;
