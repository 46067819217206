import React from "react";
import { Center, SimpleGrid, Text, VStack } from "@chakra-ui/react";

const Dashboard = () => {
  return (
    <VStack
      w={"full"}
      pb={"6"}
      align={"flex-start"}
      justify={"flex-start"}
      spacing={6}
    >
      <SimpleGrid w={"full"} columns={[1, 1, 3, 3, 3, 3]} gap={6}>
        {[1, 2, 3].map((e, i) => (
          <Center
            key={i}
            minH={"32"}
            bg={"#f9f9f9"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            borderTopWidth={"2px"}
            borderTopColor={"#F07F1F"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {e}
            </Text>
          </Center>
        ))}
      </SimpleGrid>
      <SimpleGrid w={"full"} columns={[1, 1, 2, 2, 4, 4]} gap={6}>
        {[1, 2, 3, 4].map((e, i) => (
          <Center
            key={i}
            minH={"32"}
            bg={"#f9f9f9"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            borderTopWidth={"2px"}
            borderTopColor={"#F07F1F"}
          >
            <Text color={"#000"} fontSize={14} fontWeight={"medium"}>
              {e}
            </Text>
          </Center>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default Dashboard;
