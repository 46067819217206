import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Image,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../utils/axios";
import ListTable from "./ListTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import GradiantBtn from "../../components/btn/GradiantBtn";
import CustomInput from "../../components/input/CustomInput";
import ImageUpload from "../../components/upload/ImageUpload";
import { IoMdSearch } from "react-icons/io";

const Product = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [indexArr, setIndexArr] = useState([]);
  //
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [image, setImage] = useState("");
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearData = () => {
    setProductName("");
    setPrice("");
    setImage("");
    setStock("");
  };

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/product/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("product list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setPageSize(parseInt(result.data.pageSize));
          setData(result.data.data);

          setIndexArr(
            generateAscendingArray(
              (result.data.currentPage - 1) * result.data.pageSize,
              result.data.pageSize
            )
          );
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  function generateAscendingArray(startingNumber, length) {
    const result = [];

    for (let i = 1; i <= length; i++) {
      result.push(startingNumber + i);
    }

    return result;
  }

  // --------------------------------- Search data ---------------------------------------
  useEffect(() => {
    if (searchData.length === 0) {
      setTrigger(true);
    }
    if (searchData && currentPage) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/product/search", {
          params: { name: searchData, page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Search data --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setPageSize(parseInt(result.data.pageSize));
          setData(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [searchData, currentPage]);

  const submitHandler = () => {
    if (productName && price && stock) {
      setIsDisabled(true);
      axios
        .post(
          "/api/product/",
          {
            name: productName,
            image: image ? image : "",
            price: parseInt(price),
            stock: parseInt(stock),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            clearData();
            onClose();
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const headerData = [
    "#",
    "Зураг",
    "Барааны нэр",
    "Үнэ",
    "Тоо ширхэг",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack w={"full"} align={"flex-start"} justify={"space-between"}>
        <InputGroup w={"sm"}>
          <InputLeftElement
            children={<Icon as={IoMdSearch} w={5} h={5} color={"gray.500"} />}
          />
          <Input
            placeholder="Бараа хайх"
            w={"full"}
            bg={"#f9f9f9"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            rounded={"none"}
            fontSize={14}
            fontWeight={"normal"}
            color={"#000"}
            value={searchData}
            onChange={(e) =>
              setSearchData(e.target.value.replace(/\s+/g, " ").trimStart())
            }
            onPaste={(e) => {
              e.preventDefault();
              const pasteData = e.clipboardData.getData("text");
              const cleanedData = pasteData.replace(/\s+/g, " ").trim();
              setSearchData(cleanedData);
            }}
            _focusVisible={{
              outline: "none",
            }}
            _hover={{
              borderColor: "#F07F1F",
            }}
          />
        </InputGroup>
        <HStack alignSelf={"flex-end"}>
          <GradiantBtn
            title={"Бараа нэмэх"}
            onClick={() => onOpen()}
            isDisabled={isDisabled}
          />
        </HStack>
      </HStack>

      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
        indexArr={indexArr}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
        pageSize={pageSize}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Бараа нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              {/* -------------- Бараа нэр ---------------- */}
              <CustomInput
                title={"Барааны нэр"}
                value={productName}
                onChange={setProductName}
                required={true}
              />
              <HStack w={"full"}>
                {/* -------------- Үнэ ---------------- */}
                <CustomInput
                  title={"Үнэ"}
                  value={price}
                  onChange={setPrice}
                  type={"number"}
                  required={true}
                />
                {/* -------------- Тоо ширхэг ---------------- */}
                <CustomInput
                  title={"Тоо ширхэг"}
                  value={stock}
                  onChange={setStock}
                  type={"number"}
                  required={true}
                />
              </HStack>
              <ImageUpload
                title={"Зураг"}
                setData={setImage}
                bgColor={"#F0F0F0"}
              />
              {image && (
                <Image src={image} objectFit={"contain"} w={"full"} h={"32"} />
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                clearData();
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={submitHandler}
              isDisabled={
                productName && price && stock && !isDisabled ? false : true
              }
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Product;
