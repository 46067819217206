import React, { useContext, useState } from "react";
import {
  Icon,
  Button,
  Center,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { HiLogout } from "react-icons/hi";
import { RiMenu4Line, RiUserSettingsLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../utils/authRequest/Authentication";
import BackButton from "../btn/BackButton";
import UserContext from "../../utils/context/UserContext";
import { LuUser } from "react-icons/lu";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import AskModal from "../modal/AskModal";
import SideBarMobile from "../sidebar/SideBarMobile";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const { logoutHandler } = useContext(AuthContext);
  const { username } = useContext(UserContext);

  // console.log("location", location.state);

  const [showSideBar, setShowSidebar] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toggleSidebar = () => {
    if (showSideBar === true) {
      setShowSidebar(onClose);
    } else {
      setShowSidebar(!isOpen);
    }
  };

  return (
    <>
      <SideBarMobile showSideBar={showSideBar} toggleSidebar={toggleSidebar} />
      <HStack
        w={"full"}
        h={"14"}
        minH={"14"}
        bg={"#f9f9f9"}
        justify={"space-between"}
        align={"center"}
        px={"4"}
        borderBottomWidth={"thin"}
        borderColor={"#eee"}
      >
        <HStack
          spacing={4}
          pl={
            location.pathname.split("/")[2] || location?.pathname === "/profile"
              ? "0"
              : "5"
          }
        >
          <Flex display={["flex", "flex", "flex", "flex", "none", "none"]}>
            <Icon
              as={RiMenu4Line}
              w={6}
              h={6}
              color="#000"
              onClick={toggleSidebar}
            />
          </Flex>
          {location.pathname.split("/")[2] ||
          location?.pathname === "/order" ||
          location?.pathname === "/profile" ? (
            <BackButton
              currentPage={location.state?.page ? location.state.page : ""}
              toLink={
                location.state?.navigate && location.state.navigate === "/"
                  ? location.state.navigate
                  : ""
              }
            />
          ) : null}
          <Text fontWeight={"medium"} fontSize={15} color={"#000"}>
            {location?.pathname === "/"
              ? "Захиалга"
              : location?.pathname === "/order"
              ? "Захиалга үүсгэх"
              : location?.pathname === "/product"
              ? "Бараа"
              : location?.pathname === "/driver"
              ? "Жолооч"
              : location?.pathname === "/driver/add"
              ? "Жолооч нэмэх"
              : location?.pathname === "/driver/area"
              ? "Жолоочийн бүс"
              : location?.pathname === "/driver/detail"
              ? "Жолоочийн дэлгэрэнгүй" + " - " + location?.state?.phoneNo
              : location?.pathname === "/zone"
              ? "Бүс"
              : location?.pathname === "/zone/detail"
              ? "Бүсийн дэлгэрэнгүй"
              : location?.pathname === "/operator"
              ? "Оператор"
              : location?.pathname === "/dashboard"
              ? "Статистик мэдээлэл"
              : location?.pathname === "/profile"
              ? "Миний мэдээлэл"
              : null}
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Popover placement="bottom-end" isLazy>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <HStack cursor={"pointer"}>
                    <Center
                      bg={"#fff"}
                      rounded={"full"}
                      borderWidth={"thin"}
                      borderColor={"#eee"}
                      p={"2"}
                    >
                      <Icon as={LuUser} w={"5"} h={"5"} />
                    </Center>
                    <Text fontWeight={"medium"} fontSize={15} color={"#000"}>
                      {username ? username : "-"}
                    </Text>

                    <Icon as={MdOutlineKeyboardArrowDown} w={"5"} h={"5"} />
                  </HStack>
                </PopoverTrigger>
                <PopoverContent w="fit-content" _focus={{ boxShadow: "none" }}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Stack spacing={0}>
                      <Button
                        w="194px"
                        variant="ghost"
                        rightIcon={<RiUserSettingsLine />}
                        justifyContent="space-between"
                        fontWeight="normal"
                        fontSize="sm"
                        onClick={() => {
                          onClose();
                          navigate("/profile");
                        }}
                        _hover={{
                          bg: "rgba(0,0,0,.03)",
                        }}
                      >
                        Миний мэдээлэл
                      </Button>
                      <Button
                        w="194px"
                        variant="ghost"
                        rightIcon={<HiLogout />}
                        justifyContent="space-between"
                        fontWeight="normal"
                        colorScheme="red"
                        fontSize="sm"
                        onClick={() => {
                          onClose();
                          setShowModal(true);
                        }}
                        _hover={{
                          bg: "rgba(255,0,0,.03)",
                        }}
                      >
                        Гарах
                      </Button>
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </HStack>

        <AskModal
          showModal={showModal}
          setShowModal={setShowModal}
          submitHandler={logoutHandler}
        />
      </HStack>
    </>
  );
};

export default Header;
