import React from "react";
import { Input, Text, VStack } from "@chakra-ui/react";

const CustomInput = ({
  title,
  value,
  onChange,
  type,
  onKeyDown,
  required,
  bgColor,
  placeholder,
}) => {
  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={0}>
      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
        {required ? (
          <Text as={"span"} color={"#ff0000"}>
            *{" "}
          </Text>
        ) : null}
        {title + ":"}
      </Text>
      <Input
        placeholder={placeholder ? placeholder : "-"}
        w={"full"}
        bg={bgColor ? bgColor : "#F0F0F0"}
        fontWeight={"normal"}
        fontSize={14}
        color={"#000"}
        type={type === "number" ? "number" : "text"}
        _focusVisible={{
          outline: "none",
          borderColor: "#F07F1F",
        }}
        _hover={{
          outline: "none",
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </VStack>
  );
};

export default CustomInput;
