import React, { useEffect, useState } from "react";
import {
  Divider,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  Icon,
  IconButton,
  Center,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import axios from "../../../utils/axios";
import Cookies from "js-cookie";
import CustomInput from "../../../components/input/CustomInput";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import GradiantBtn from "../../../components/btn/GradiantBtn";
import DeleteModal from "../../../components/modal/DeleteModal";

const ZoneDetail = () => {
  const location = useLocation();
  const [trigger, setTrigger] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [mainAreaName, setMainAreaName] = useState(
    location?.state?.areaName ? location.state.areaName : ""
  );
  const [mainArea, setMainArea] = useState("");
  const [editSubArea, setEditSubArea] = useState("");
  const [subAreaName, setSubAreaName] = useState("");
  const [subArea, setSubArea] = useState("");
  const [subAreaId, setSubAreaId] = useState("");
  const [secondAreaName, setSecondAreaName] = useState("");
  //
  const [deleteId, setDeleteId] = useState("");
  const [delDisabled, setDelDisabled] = useState(false);
  const [secondSubAreaDelModal, setSecondSubAreaDelModal] = useState(false);
  const [subAreaDelModal, setSubAreaDelModal] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (trigger && location?.state?._id) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/area/sub", {
          params: {
            areaId: location?.state?._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("zone detail --------->", result.data);
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const mainAreaHandler = () => {
    if (location?.state?._id && mainArea) {
      setIsDisabled(true);
      axios
        .put(
          "/api/area/name",
          {
            areaId: location.state._id,
            newAreaName: mainArea,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setMainAreaName(mainArea);
            setEdit(false);
            setMainArea("");
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const subAreaHandler = (subAreaId) => {
    if (subAreaId && subArea) {
      setIsDisabled(true);
      axios
        .put(
          "/api/area/sub",
          {
            subAreaId: subAreaId,
            newSubAreaName: subArea,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setTrigger(true);
            setEditSubArea("");
            setSubArea("");
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const secondSubHandler = () => {
    if (subAreaId && secondAreaName) {
      setIsDisabled(true);
      axios
        .post(
          "/api/area/second_sub",
          {
            subAreaId: subAreaId,
            secondSubAreaName: secondAreaName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            setSecondAreaName("");
            setSubAreaId("");
            onClose();
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const subHandler = () => {
    if (subAreaName && location?.state?._id) {
      setIsDisabled(true);
      axios
        .post(
          "/api/area/sub",
          {
            mainAreaId: location?.state?._id,
            areaName: subAreaName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            setTrigger(true);
            setSubAreaName("");
            setShowModal(false);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const subAreaDeleteHandler = () => {
    if (deleteId) {
      setDelDisabled(true);
      axios
        .delete("/api/area/sub", {
          data: {
            areaId: deleteId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setDeleteId("");
          setSubAreaDelModal(false);
          setTrigger(true);
          setDelDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setDelDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  const secondSubDeleteHandler = () => {
    if (deleteId) {
      setDelDisabled(true);
      axios
        .delete("/api/area/second_sub", {
          data: {
            areaId: deleteId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setDeleteId("");
          setSecondSubAreaDelModal(false);
          setTrigger(true);
          setDelDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setDelDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };
  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      {isLoading ? (
        <Spinner color={"#F07F1F"} alignSelf={"center"} />
      ) : data.length > 0 ? (
        <VStack
          w={"full"}
          align={"flex-start"}
          justify={"flex-start"}
          spacing={4}
        >
          <HStack w={"full"} align={"flex-end"} justify={"space-between"}>
            {edit ? (
              <HStack w={"sm"}>
                <CustomInput
                  placeholder={mainAreaName}
                  title={"Дүүрэг"}
                  value={mainArea}
                  onChange={setMainArea}
                  bgColor={"#f9f9f9"}
                />
                <IconButton
                  isLoading={isDisabled}
                  variant="solid"
                  alignSelf={"flex-end"}
                  colorScheme="orange"
                  aria-label="Done"
                  fontSize="15"
                  icon={<Icon as={IoMdCheckmark} />}
                  onClick={() => mainAreaHandler()}
                  isDisabled={mainArea && !isDisabled ? false : true}
                  _focusVisible={{
                    outline: "none",
                  }}
                />
                <IconButton
                  variant="solid"
                  alignSelf={"flex-end"}
                  colorScheme="gray"
                  aria-label="Close"
                  fontSize="15"
                  icon={<Icon as={IoMdClose} />}
                  onClick={() => {
                    setEdit(false);
                    setMainArea("");
                  }}
                  _focusVisible={{
                    outline: "none",
                  }}
                />
              </HStack>
            ) : (
              <VStack
                w={"sm"}
                align={"flex-start"}
                justify={"flex-end"}
                spacing={0}
              >
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Дүүрэг:
                </Text>
                <HStack w={"full"}>
                  <HStack
                    w={"full"}
                    bg={"#f9f9f9"}
                    h={"10"}
                    px={"4"}
                    borderWidth={"thin"}
                    rounded={"md"}
                    align={"center"}
                  >
                    <Text
                      fontSize={14}
                      color={"#000"}
                      fontWeight={"medium"}
                      noOfLines={1}
                    >
                      {mainAreaName}
                    </Text>
                  </HStack>
                  <IconButton
                    variant="solid"
                    alignSelf={"flex-end"}
                    colorScheme="gray"
                    aria-label="Close"
                    fontSize="15"
                    icon={<Icon as={FiEdit} />}
                    onClick={() => setEdit(true)}
                    _focusVisible={{
                      outline: "none",
                    }}
                  />
                </HStack>
              </VStack>
            )}
            <HStack w={"60"}>
              <GradiantBtn
                title={"Чиглэл нэмэх"}
                onClick={() => setShowModal(true)}
                isDisabled={isDisabled}
              />
            </HStack>
          </HStack>
          <SimpleGrid w={"full"} columns={[1, 1, 2, 2, 4, 4]} gap={4}>
            {data.map((e, i) => (
              <VStack
                key={e._id}
                w={"full"}
                h={"max-content"}
                bg="#f9f9f9"
                borderWidth={"thin"}
                align={"flex-start"}
                justify={"flex-start"}
                py={"3"}
                px={"4"}
              >
                {editSubArea === e._id ? (
                  <HStack w={"full"}>
                    <CustomInput
                      placeholder={e.areaName}
                      title={"Чиглэл"}
                      value={subArea}
                      onChange={setSubArea}
                      bgColor={"#fff"}
                    />
                    <IconButton
                      isLoading={isDisabled}
                      variant="solid"
                      alignSelf={"flex-end"}
                      colorScheme="orange"
                      aria-label="Done"
                      fontSize="15"
                      icon={<Icon as={IoMdCheckmark} />}
                      onClick={() => subAreaHandler(e._id)}
                      isDisabled={subArea && !isDisabled ? false : true}
                      _focusVisible={{
                        outline: "none",
                      }}
                    />
                    <IconButton
                      variant="solid"
                      alignSelf={"flex-end"}
                      colorScheme="gray"
                      aria-label="Close"
                      fontSize="15"
                      icon={<Icon as={IoMdClose} />}
                      onClick={() => {
                        setEditSubArea("");
                        setSubArea("");
                      }}
                      _focusVisible={{
                        outline: "none",
                      }}
                    />
                  </HStack>
                ) : (
                  <VStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"flex-end"}
                    spacing={0}
                  >
                    <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                      Чиглэл:
                    </Text>
                    <HStack w={"full"}>
                      <HStack
                        w={"full"}
                        bg={"#fff"}
                        h={"10"}
                        px={"4"}
                        borderWidth={"thin"}
                        rounded={"md"}
                        align={"center"}
                      >
                        <Text
                          fontSize={14}
                          color={"#000"}
                          fontWeight={"medium"}
                        >
                          {e.areaName}
                        </Text>
                      </HStack>
                      <IconButton
                        variant="solid"
                        alignSelf={"flex-end"}
                        colorScheme="gray"
                        aria-label="Close"
                        fontSize="15"
                        icon={<Icon as={FiEdit} />}
                        onClick={() => setEditSubArea(e._id)}
                        _focusVisible={{
                          outline: "none",
                        }}
                      />
                      <IconButton
                        variant="solid"
                        alignSelf={"flex-end"}
                        colorScheme="red"
                        aria-label="Close"
                        fontSize="15"
                        icon={<Icon as={FiTrash2} />}
                        onClick={() => {
                          setDeleteId(e._id);
                          setSubAreaDelModal(true);
                        }}
                        _focusVisible={{
                          outline: "none",
                        }}
                      />
                    </HStack>
                  </VStack>
                )}
                <Divider />
                <VStack w={"full"} align={"flex-start"} justify={"flex-start"}>
                  {e.secondSubAreas.length > 0 ? (
                    <VStack
                      w={"full"}
                      align={"flex-start"}
                      justify={"flex-end"}
                      spacing={0}
                    >
                      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                        Орчим:
                      </Text>
                      <VStack
                        w={"full"}
                        align={"flex-start"}
                        justify={"flex-end"}
                      >
                        {e.secondSubAreas?.map((sub, i) => (
                          <HStack
                            key={i}
                            w={"full"}
                            bg={"#fff"}
                            minH={"10"}
                            py={"1"}
                            px={"4"}
                            borderWidth={"thin"}
                            rounded={"md"}
                            align={"center"}
                            justify={"space-between"}
                          >
                            <Text
                              color={"#000"}
                              fontWeight={"medium"}
                              fontSize={14}
                            >
                              {sub.areaName}
                            </Text>
                            <Icon
                              as={IoClose}
                              w={"5"}
                              h={"5"}
                              color={"#ff0000"}
                              onClick={() => {
                                setDeleteId(sub._id);
                                setSecondSubAreaDelModal(true);
                              }}
                              cursor={"pointer"}
                            />
                          </HStack>
                        ))}
                      </VStack>
                    </VStack>
                  ) : null}
                  <Button
                    variant={"link"}
                    my={"2"}
                    alignSelf={"center"}
                    color={"#F07F1F"}
                    fontWeight={"medium"}
                    fontSize={14}
                    leftIcon={<Icon as={FiPlus} />}
                    onClick={() => {
                      setSubAreaId(e._id);
                      onOpen();
                    }}
                    _active={{
                      color: "#F95077",
                    }}
                  >
                    Орчим нэмэх
                  </Button>
                </VStack>
              </VStack>
            ))}
          </SimpleGrid>
        </VStack>
      ) : null}

      {/* ---------------------------------------- delete subArea ------------------------------------------- */}
      <DeleteModal
        showModal={subAreaDelModal}
        setShowModal={setSubAreaDelModal}
        warntext={
          "Тухайн чиглэлийг устгаснаар хамаарагдах бүх орчим болон захиалга устана гэдгийг анхаарна уу!"
        }
        isDisable={delDisabled}
        deleteHandler={subAreaDeleteHandler}
      />

      {/* ---------------------------------------- delete secondSubArea ------------------------------------------- */}
      <DeleteModal
        showModal={secondSubAreaDelModal}
        setShowModal={setSecondSubAreaDelModal}
        warntext={
          "Тухайн орчмыг устгаснаар хамаарагдах бүх захиалга устана гэдгийг анхаарна уу!"
        }
        isDisable={delDisabled}
        deleteHandler={secondSubDeleteHandler}
      />

      {/* ---------------------------------------- Add secondSubArea ------------------------------------------- */}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Орчим нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              <CustomInput
                title={"Орчим"}
                value={secondAreaName}
                onChange={setSecondAreaName}
                required={true}
                onKeyDown={(e) => {
                  if (secondAreaName) {
                    if (e.key === "Enter") {
                      secondSubHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setSecondAreaName("");
                setSubAreaId("");
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={secondSubHandler}
              isDisabled={secondAreaName && !isDisabled ? false : true}
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ---------------------------------------- Sub area------------------------------------------- */}
      <Modal
        isCentered
        onClose={!showModal}
        isOpen={showModal}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={"sm"} rounded={"sm"}>
          <ModalHeader fontSize={17}>Чиглэл нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              <CustomInput
                title={"Чиглэл"}
                value={subAreaName}
                onChange={setSubAreaName}
                required={true}
                onKeyDown={(e) => {
                  if (subAreaName) {
                    if (e.key === "Enter") {
                      subHandler();
                    }
                  }
                }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                setShowModal(false);
                setSubAreaName("");
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={subHandler}
              isDisabled={subAreaName && !isDisabled ? false : true}
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ZoneDetail;
