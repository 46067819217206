import React, { useRef } from "react";
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import DetailTable from "./DetailTable";

const DetailDrawer = ({ data, isOpen, onClose }) => {
  const btnRef = useRef();

  const headerData = ["#", "Зураг", "Нэр", "Үнэ", "Тоо ширхэг", "Нийт үнэ"];

  const totalPrice = data?.products
    ? data.products
        .map((e) =>
          e.product?.price ? e.quantity * e.product?.price : e.priceEach
        )
        .reduce((acc, curr) => acc + curr, 0)
    : 0;

  const totalProduct = data?.products
    ? data.products.map((e) => e.quantity).reduce((acc, curr) => acc + curr, 0)
    : 0;

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size={"xl"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          _focus={{
            outline: "none",
          }}
        />
        <DrawerHeader fontSize={17}>Захиалгын дэлгэрэнгүй</DrawerHeader>

        <DrawerBody>
          <VStack spacing={4}>
            <Stack
              direction={["column", "column", "row", "row", "row", "row"]}
              w={"full"}
              spacing={8}
              align={"flex-start"}
            >
              <VStack w={"full"}>
                <HStack w={"full"} align={"center"} justify={"space-between"}>
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Огноо:
                  </Text>
                  <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                    {data.createdAt}
                  </Text>
                </HStack>

                <HStack w={"full"} align={"center"} justify={"space-between"}>
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Төлөв:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={
                      data.status === "pending"
                        ? "#F07F1F"
                        : data.status === "driverTaken"
                        ? "#0042FF"
                        : data.status === "delivered"
                        ? "#45A735"
                        : "#ff0000"
                    }
                  >
                    {/* pending, driverTaken, delivered, cancelled, notPaid, */}
                    {data.status === "pending"
                      ? "Хүлээгдэж буй"
                      : data.status === "notPaid"
                      ? "Төлөгдөөгүй"
                      : data.status === "driverTaken"
                      ? "Жолооч авсан"
                      : data.status === "delivered"
                      ? "Хүргэгдсэн"
                      : "Цуцлагдсан"}
                  </Text>
                </HStack>
                <HStack w={"full"} align={"center"} justify={"space-between"}>
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Утасны дугаар:
                  </Text>
                  <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                    {data.phoneNo}
                  </Text>
                </HStack>

                <HStack w={"full"} align={"center"} justify={"space-between"}>
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Хүргэгдэх хугацаа:
                  </Text>
                  <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                    {data.shouldBeDeliveredAt}
                  </Text>
                </HStack>
                <HStack w={"full"} align={"center"} justify={"space-between"}>
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Барааны тоо ширхэг:
                  </Text>
                  <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                    {totalProduct + "ш"}
                  </Text>
                </HStack>
                <HStack w={"full"} align={"center"} justify={"space-between"}>
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Нийт үнэ:
                  </Text>
                  <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                    {totalPrice.toLocaleString() + "₮"}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Захиалгын төрөл:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.orderType
                      ? data.orderType === "call"
                        ? "Утас"
                        : "Facebook"
                      : "-"}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Захиалга авсан:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.admin?.username ? data.admin.username : "-"}
                  </Text>
                </HStack>
              </VStack>
              <VStack w={"full"}>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Дүүрэг:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.area?.mainArea ? data.area.mainArea : "-"}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Чиглэл:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.area?.subArea ? data.area.subArea : "-"}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Орчим:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.area?.areaName ? data.area.areaName : "-"}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Хаяг:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.address ? data.address : "-"}
                  </Text>
                </HStack>
                <HStack
                  w={"full"}
                  align={"flex-start"}
                  justify={"space-between"}
                >
                  <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                    Тайлбар:
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"#000"}
                    textAlign={"end"}
                  >
                    {data.description ? data.description : "-"}
                  </Text>
                </HStack>
                {data.status === "cancelled" ? (
                  <HStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"space-between"}
                  >
                    <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                      Цуцалсан тайлбар:
                    </Text>
                    <Text
                      fontSize={14}
                      fontWeight={"medium"}
                      color={"#000"}
                      textAlign={"end"}
                    >
                      {data.cancelDescription ? data.cancelDescription : "-"}
                    </Text>
                  </HStack>
                ) : null}
                {data.status === "driverTaken" ||
                data.status === "delivered" ||
                data.status === "cancelled" ? (
                  <VStack
                    w={"full"}
                    borderWidth={"thin"}
                    borderColor={"#F07F1F"}
                    rounded={"md"}
                    py={"3"}
                    px={"5"}
                    pos={"relative"}
                    mt={"2"}
                  >
                    <HStack
                      w={"full"}
                      align={"flex-start"}
                      justify={"space-between"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        Овог нэр:
                      </Text>
                      <Text
                        fontSize={14}
                        fontWeight={"medium"}
                        color={"#000"}
                        textAlign={"end"}
                      >
                        {data.takenBy?.firstName && data.takenBy?.lastName
                          ? data.takenBy.lastName + " " + data.takenBy.firstName
                          : "-"}
                      </Text>
                    </HStack>
                    <HStack
                      w={"full"}
                      align={"flex-start"}
                      justify={"space-between"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        Утас:{" "}
                      </Text>
                      <Text
                        fontSize={14}
                        fontWeight={"medium"}
                        color={"#000"}
                        textAlign={"end"}
                      >
                        {data.takenBy?.phoneNo ? data.takenBy.phoneNo : "-"}
                      </Text>
                    </HStack>
                    <Center
                      bg={"#fff"}
                      pos={"absolute"}
                      top={-3}
                      left={3}
                      px={"1"}
                    >
                      <Text fontSize={14} fontWeight={"medium"} color={"#000"}>
                        Жолоочийн мэдээлэл
                      </Text>
                    </Center>
                  </VStack>
                ) : null}
              </VStack>
            </Stack>

            {data?.products?.length > 0 ? (
              <DetailTable data={data.products} headerData={headerData} />
            ) : null}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DetailDrawer;
