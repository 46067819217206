import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";

const DeleteModal = ({
  warntext,
  deleteHandler,
  isDisable,
  setShowModal,
  showModal,
}) => {
  return (
    <Modal
      isCentered
      onClose={!showModal}
      isOpen={showModal}
      motionPreset="scale"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w={{ base: "xs", md: "sm" }} rounded={"sm"}>
        <ModalHeader fontSize={17}>Устгах уу?</ModalHeader>
        <ModalBody>
          <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
            {warntext}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="gray"
            w={"full"}
            h={"9"}
            mr={3}
            px={"8"}
            color={"#000"}
            rounded={"md"}
            fontSize={14}
            fontWeight={"medium"}
            _focusVisible={{
              outline: "none",
            }}
            onClick={() => setShowModal(false)}
          >
            Үгүй
          </Button>
          <Button
            colorScheme="orange"
            w={"full"}
            h={"9"}
            px={"8"}
            color={"#fff"}
            rounded={"md"}
            fontSize={14}
            fontWeight={"medium"}
            _focusVisible={{
              outline: "none",
            }}
            onClick={deleteHandler}
            isDisabled={isDisable}
          >
            {isDisable ? <Spinner mr={2} size="sm" /> : null}
            Тийм
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
