import React, { useEffect, useState } from "react";
import { HStack, useToast, VStack } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "../../utils/axios";
import ListTable from "./ListTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import GradiantBtn from "../../components/btn/GradiantBtn";
import { useNavigate } from "react-router-dom";

const Driver = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  //
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const navigate = useNavigate();

  useEffect(() => {
    if (trigger) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/driver/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("driver list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setData(result.data.data);
          setPageSize(parseInt(result.data.pageSize));
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Овог Нэр",
    "Регистр дугаар",
    "Утас",
    "Гэрийн хаяг",
    "Машины марк",
    "Машины номер",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack alignSelf={"flex-end"}>
        <GradiantBtn
          title={"Жолооч нэмэх"}
          onClick={() => navigate("/driver/add")}
        />
      </HStack>
      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
        pageSize={pageSize}
      />
    </VStack>
  );
};

export default Driver;
