import React, { useEffect, useState } from "react";
import {
  HStack,
  InputGroup,
  InputLeftElement,
  useToast,
  VStack,
  Icon,
  Input,
  Stack,
} from "@chakra-ui/react";
import GradiantBtn from "../../components/btn/GradiantBtn";
import Cookies from "js-cookie";
import axios from "../../utils/axios";
import ListTable from "./ListTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";

const Order = () => {
  const location = useLocation();
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    location.state?.page ? location.state.page : 1
  );
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [indexArr, setIndexArr] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [date, setDate] = useState("");
  const navigate = useNavigate();
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";

  function generateAscendingArray(startingNumber, length) {
    const result = [];

    for (let i = 1; i <= length; i++) {
      result.push(startingNumber + i);
    }

    return result;
  }

  useEffect(() => {
    if (trigger && currentPage) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/order/", {
          params: { page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("order list --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setPageSize(parseInt(result.data.pageSize));
          setData(result.data.data);
          setIndexArr(
            generateAscendingArray(
              (result.data.currentPage - 1) * result.data.pageSize,
              result.data.pageSize
            )
          );
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  // --------------------------------- Search data ---------------------------------------
  useEffect(() => {
    if (searchData.length === 0 && date.length === 0) {
      setTrigger(true);
    }
    if ((searchData || date) && currentPage) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/order/search_phone_no", {
          params: {
            phoneNo: searchData ? searchData : "noPhone",
            page: currentPage,
            shouldBeDeliveredAt: date ? date : "noDate",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("Search data --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.count));
          setPageSize(parseInt(result.data.pageSize));
          setIndexArr(
            generateAscendingArray(
              (result.data.currentPage - 1) * result.data.pageSize,
              result.data.pageSize
            )
          );
          setData(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [searchData, currentPage, date]);

  const headerData = [
    "#",
    "Хугацаа",
    "Бараа",
    "Орчим",
    "Захиалгачийн хаяг",
    "Утас",
    "Тайлбар",
    "Захиалга",
    "Захиалга авсан",
    "Төлөв",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <Stack
        direction={["column", "column", "column", "column", "row", "row"]}
        w={"full"}
        align={"flex-start"}
        justify={"space-between"}
      >
        <HStack w={"full"}>
          <InputGroup w={["full", "full", "full", "full", "sm", "sm"]}>
            <InputLeftElement
              children={<Icon as={IoMdSearch} w={5} h={5} color={"gray.500"} />}
            />
            <Input
              placeholder="Утасны дугаараар хайх"
              w={"full"}
              bg={"#f9f9f9"}
              borderWidth={"thin"}
              borderColor={"#eee"}
              rounded={"none"}
              fontSize={14}
              fontWeight={"normal"}
              color={"#000"}
              value={searchData}
              onChange={(e) =>
                setSearchData(e.target.value.replace(/\s+/g, " ").trimStart())
              }
              onPaste={(e) => {
                e.preventDefault();
                const pasteData = e.clipboardData.getData("text");
                const cleanedData = pasteData.replace(/\s+/g, " ").trim();
                setSearchData(cleanedData);
              }}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                borderColor: "#F07F1F",
              }}
            />
          </InputGroup>
          <Input
            placeholder={"-"}
            w={"36"}
            bg={"#f9f9f9"}
            fontWeight={"normal"}
            fontSize={14}
            color={"#000"}
            rounded={"none"}
            borderWidth={"thin"}
            borderColor={"#eee"}
            type="date"
            _focusVisible={{
              outline: "none",
              borderColor: "#F07F1F",
            }}
            _hover={{
              outline: "none",
            }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </HStack>
        <HStack alignSelf={"flex-end"}>
          <GradiantBtn
            title={"Захиалга шивэх"}
            onClick={() => navigate("/order")}
            // isDisabled={isDisabled}
          />
        </HStack>
      </Stack>

      <ListTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
        currentPage={currentPage}
        indexArr={indexArr}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
        pageSize={pageSize}
      />
    </VStack>
  );
};

export default Order;
