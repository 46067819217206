import React, { useEffect, useState } from "react";
import {
  Center,
  HStack,
  Image,
  VStack,
  Icon,
  Text,
  useToast,
  Select,
  Divider,
  IconButton,
  StackDivider,
  Input,
  Stack,
} from "@chakra-ui/react";
import axios from "../../../utils/axios";
import { MdOutlineImageNotSupported } from "react-icons/md";
import emptyBox from "../../../images/empty-box.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CustomTextArea from "../../../components/input/CustomTextArea";
import CustomInput from "../../../components/input/CustomInput";
import { IoClose } from "react-icons/io5";
import GradiantBtn from "../../../components/btn/GradiantBtn";
import { FiMinus, FiPlus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

const RightSide = ({
  otherData,
  selectedProduct,
  setSelectedProduct,
  setTrigger,
}) => {
  const [reloadDate, setReloadData] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editId, setEditId] = useState(otherData?._id ? otherData._id : "");
  const [area, setArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState(
    otherData?.area?.mainAreaId ? otherData.area.mainAreaId : ""
  );
  const [subArea, setSubArea] = useState([]);
  const [selectedSubArea, setSelectedSubArea] = useState(
    otherData?.area?.subAreaId ? otherData.area.subAreaId : ""
  );
  const [selectedSecondSubArea, setSelectedSecondSubArea] = useState(
    otherData?.area?._id ? otherData.area._id : ""
  );
  const [selectedOrderType, setSelectedOrderType] = useState(
    otherData?.orderType ? otherData.orderType : ""
  );
  //
  const [phoneNo, setPhoneNo] = useState(
    otherData?.phoneNo ? otherData.phoneNo : ""
  );
  const [description, setDescription] = useState(
    otherData?.description ? otherData.description : ""
  );
  const [address, setAddress] = useState(
    otherData?.address ? otherData.address : ""
  );
  const [date, setDate] = useState(
    otherData?.shouldBeDeliveredAt ? otherData.shouldBeDeliveredAt : ""
  );
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";
  const navigate = useNavigate();

  useEffect(() => {
    if (!otherData?.shouldBeDeliveredAt && reloadDate) {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");

      setDate(`${year}-${month}-${day}`);
      setReloadData(false);
    }
  }, [reloadDate]);

  useEffect(() => {
    setIsLoading(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/api/area/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        setIsLoading(false);
        // console.log("zone list --------->", result.data);
        setArea(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedArea) {
      // setSelectedSubArea("");
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/area/sub", {
          params: { areaId: selectedArea },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("sub list --------->", result.data);
          setSubArea(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [selectedArea]);

  const addHandler = (value) => {
    const clone = [...selectedProduct];
    const updatedClone = clone.map((e) => {
      if (e._id === value._id) {
        return {
          ...e,
          quantity: e.quantity + 1,
        };
      }
      return e;
    });
    setSelectedProduct(updatedClone);
  };

  const removeHandler = (value) => {
    const clone = [...selectedProduct];
    const updatedClone = clone.map((e) => {
      if (e._id === value._id && e.quantity > 1) {
        return {
          ...e,
          quantity: e.quantity - 1,
        };
      }
      return e;
    });
    setSelectedProduct(updatedClone);
  };

  const deleteHandler = (value) => {
    const clone = [...selectedProduct];
    const updatedClone = clone.filter((e) => {
      return e._id !== value._id;
    });
    setSelectedProduct(updatedClone);
  };

  const submitHandler = () => {
    if (
      editId &&
      address &&
      phoneNo &&
      date &&
      selectedSecondSubArea &&
      selectedOrderType &&
      selectedProduct.length > 0
    ) {
      // console.log("edit", {
      //   orderId: editId,
      //   products: selectedProduct.map((e) => ({
      //     product: e._id,
      //     quantity: e.quantity,
      //     description: e.description,
      //   })),
      //   address: address,
      //   secondSubArea: selectedSecondSubArea,
      //   phoneNo: phoneNo,
      //   orderType: selectedOrderType,
      // });
      setIsDisabled(true);
      axios
        .put(
          "/api/order/",
          {
            orderId: editId,
            products: selectedProduct.map((e) => ({
              product: e._id,
              quantity: e.quantity,
              description: e.description,
            })),
            address: address,
            secondSubArea: selectedSecondSubArea,
            phoneNo: phoneNo,
            orderType: selectedOrderType,
            description: description,
            shouldBeDeliveredAt: date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setAddress("");
            setSelectedSecondSubArea("");
            setPhoneNo("");
            setDescription("");
            setSelectedOrderType("");
            setSelectedProduct([]);
            setSelectedArea("");
            setSelectedSubArea("");
            navigate("/");
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    } else {
      if (
        address &&
        phoneNo &&
        date &&
        selectedSecondSubArea &&
        selectedOrderType &&
        selectedProduct.length > 0
      ) {
        // console.log(
        //   selectedProduct.map((e) => ({
        //     product: e._id,
        //     quantity: e.quantity,
        //     description: e.description,
        //   }))
        // );
        setIsDisabled(true);
        axios
          .post(
            "/api/order/",
            {
              products: selectedProduct.map((e) => ({
                product: e._id,
                quantity: e.quantity,
                description: e.description,
              })),
              address: address,
              secondSubArea: selectedSecondSubArea,
              phoneNo: phoneNo,
              orderType: selectedOrderType,
              description: description,
              shouldBeDeliveredAt: date,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            // console.log(result.data);
            if (result.data.success) {
              if (!toast.isActive(id)) {
                toast({
                  id,
                  duration: 2000,
                  position: "top",
                  status: "success",
                  description: "Амжилттай",
                });
              }
              setAddress("");
              setSelectedSecondSubArea("");
              setPhoneNo("");
              setDate("");
              setDescription("");
              setSelectedOrderType("");
              setSelectedProduct([]);
              setSelectedArea("");
              setSelectedSubArea("");
              setReloadData(true);
              setTrigger(true);
            }
            setIsDisabled(false);
          })
          .catch((err) => {
            // console.log(err);
            setIsDisabled(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: err.response.data.error
                  ? err.response.data.error
                  : "Алдаа гарлаа!",
              });
            }
          });
      }
    }
  };

  const totalPrice = selectedProduct
    .map((e) => e.quantity * e.price)
    .reduce((acc, curr) => acc + curr, 0);

  const totalProduct = selectedProduct
    .map((e) => e.quantity)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <VStack w={"full"}>
      <Stack
        direction={["column", "column", "row", "row", "row", "row"]}
        w={"full"}
        align={"flex-start"}
        spacing={4}
      >
        <VStack w={"full"} align={"flex-start"}>
          {/* -------------- Утас ---------------- */}
          <CustomInput
            title={"Утас"}
            value={phoneNo}
            onChange={setPhoneNo}
            // type={"number"}
            required={true}
            bgColor={"#fff"}
          />
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              <Text as={"span"} color={"#ff0000"}>
                *{" "}
              </Text>
              Захиалгын төрөл:
            </Text>
            <Select
              w={"full"}
              bg={"#fff"}
              size={"md"}
              rounded={"md"}
              fontWeight={"normal"}
              fontSize={14}
              color={"#000"}
              value={selectedOrderType}
              onChange={(e) => setSelectedOrderType(e.target.value)}
              _focusVisible={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
              _hover={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
            >
              <option selected hidden disabled value="">
                -
              </option>
              {["messenger", "call"].map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </Select>
          </VStack>

          {/* -------------- Date ---------------- */}
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              <Text as={"span"} color={"#ff0000"}>
                *{" "}
              </Text>
              Хүргэгдэх хугацаа:
            </Text>
            <Input
              placeholder={"-"}
              w={"full"}
              bg={"#fff"}
              fontWeight={"normal"}
              fontSize={14}
              color={"#000"}
              type="date"
              _focusVisible={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
              _hover={{
                outline: "none",
              }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </VStack>
          {/* -------------- Тайлбар ---------------- */}
          <CustomTextArea
            title={"Тайлбар"}
            value={description}
            onChange={setDescription}
            bgColor={"#fff"}
          />
        </VStack>
        <VStack w={"full"} align={"flex-start"}>
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              <Text as={"span"} color={"#ff0000"}>
                *{" "}
              </Text>
              Дүүрэг:
            </Text>
            <Select
              w={"full"}
              bg={"#fff"}
              size={"md"}
              rounded={"md"}
              fontWeight={"normal"}
              fontSize={14}
              color={"#000"}
              value={selectedArea}
              onChange={(e) => {
                setSelectedSubArea("");
                setSelectedArea(e.target.value);
              }}
              _focusVisible={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
              _hover={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
            >
              <option selected hidden disabled value="">
                -
              </option>
              {area.length > 0
                ? area.map((e) => (
                    <option key={e._id} value={e._id}>
                      {e.areaName}
                    </option>
                  ))
                : null}
            </Select>
          </VStack>
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              <Text as={"span"} color={"#ff0000"}>
                *{" "}
              </Text>
              Чиглэл:
            </Text>
            <Select
              w={"full"}
              bg={"#fff"}
              size={"md"}
              rounded={"md"}
              fontWeight={"normal"}
              fontSize={14}
              color={"#000"}
              value={selectedSubArea}
              onChange={(e) => {
                setSelectedSecondSubArea("");
                setSelectedSubArea(e.target.value);
              }}
              _focusVisible={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
              _hover={{
                outline: "none",
                borderColor: "#F07F1F",
              }}
            >
              <option selected hidden disabled value="">
                -
              </option>
              {subArea.length > 0
                ? subArea.map((e) => (
                    <option key={e._id} value={e._id}>
                      {e.areaName}
                    </option>
                  ))
                : null}
            </Select>
          </VStack>
          <VStack w={"full"} align={"flex-start"} spacing={0}>
            <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
              <Text as={"span"} color={"#ff0000"}>
                *{" "}
              </Text>
              Орчим:
            </Text>
            <VStack w={"full"} align={"flex-start"} spacing={1}>
              <Select
                w={"full"}
                bg={"#fff"}
                size={"md"}
                rounded={"md"}
                fontWeight={"normal"}
                fontSize={14}
                color={"#000"}
                value={selectedSecondSubArea}
                onChange={(e) => setSelectedSecondSubArea(e.target.value)}
                _focusVisible={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
                _hover={{
                  outline: "none",
                  borderColor: "#F07F1F",
                }}
              >
                <option selected hidden disabled value="">
                  -
                </option>
                {subArea
                  .filter((e) => e._id === selectedSubArea)
                  .map((filteredSubArea) =>
                    filteredSubArea.secondSubAreas.map((a) => (
                      <option key={a._id} value={a._id}>
                        {a.areaName}
                      </option>
                    ))
                  )}
              </Select>
            </VStack>
          </VStack>
        </VStack>
      </Stack>
      {/* -------------- Гэрийн хаяг ---------------- */}
      <CustomTextArea
        title={"Гэрийн хаяг"}
        value={address}
        onChange={setAddress}
        required={true}
        bgColor={"#fff"}
      />
      <Divider my={"2"} />
      <VStack
        w={"full"}
        align={"flex-start"}
        justify={"flex-start"}
        spacing={0}
      >
        <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
          <Text as={"span"} color={"#ff0000"}>
            *{" "}
          </Text>
          Бараа:
        </Text>
        <VStack
          w={"full"}
          minH={"20"}
          p={"2"}
          borderWidth={"thin"}
          bg={"#fff"}
          align={"flex-start"}
          justify={"center"}
          divider={<StackDivider />}
        >
          {selectedProduct.length > 0 ? (
            <Stack
              direction={["column", "column", "row", "row", "row", "row"]}
              w={"full"}
              minH={"14"}
              px={"2"}
              spacing={4}
              divider={
                <StackDivider
                  display={["none", "none", "flex", "flex", "flex", "flex"]}
                />
              }
            >
              <HStack w={"full"} align={"center"} justify={"space-between"}>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Нийт бараа:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {totalProduct + "ш"}
                </Text>
              </HStack>
              <HStack w={"full"} align={"center"} justify={"space-between"}>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Нийт үнэ:
                </Text>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  {totalPrice.toLocaleString() + "₮"}
                </Text>
              </HStack>
              <Icon
                as={IoMdClose}
                color={"#e04e61"}
                w={"5"}
                h={"5"}
                alignSelf={"center"}
                onClick={() => setSelectedProduct([])}
                cursor={"pointer"}
              />
            </Stack>
          ) : null}

          {selectedProduct.length > 0 ? (
            selectedProduct.map((e, i) => (
              <Stack
                direction={["column", "column", "row", "row", "row", "row"]}
                key={e._id}
                w={"full"}
                minH={"14"}
                px={"2"}
                align={[
                  "flex-start",
                  "flex-start",
                  "center",
                  "center",
                  "center",
                  "center",
                ]}
                justify={"space-between"}
                // bg={"#f9f9f9"}
                // borderWidth={"thin"}
              >
                <HStack>
                  {e.image ? (
                    <Image
                      src={e.image}
                      w={"10"}
                      h={"10"}
                      objectFit={"contain"}
                    />
                  ) : (
                    <Center w={"10"} h={"10"}>
                      <Icon
                        as={MdOutlineImageNotSupported}
                        w={"6"}
                        h={"6"}
                        color={"#979797"}
                      />
                    </Center>
                  )}
                  <VStack align={"flex-start"} spacing={0}>
                    <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                      {e.name}
                    </Text>
                    <HStack>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        Үнэ:{" "}
                        <Text as={"span"} fontWeight={"medium"}>
                          {e.price.toLocaleString() + "₮"}
                        </Text>
                      </Text>
                      <Text fontSize={14} color={"#000"} fontWeight={"normal"}>
                        Тоо ширхэг:{" "}
                        <Text as={"span"} fontWeight={"medium"}>
                          {e.stock + " ш"}
                        </Text>
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
                <HStack spacing={4} divider={<StackDivider />}>
                  <HStack bg={"#D9D9D9"} p={"1"} rounded={"lg"}>
                    <IconButton
                      rounded={"lg"}
                      size={"sm"}
                      bg={"#fff"}
                      icon={<FiMinus color="#000" />}
                      _focusVisible={{
                        outline: "none",
                      }}
                      onClick={() => removeHandler(e)}
                      isDisabled={e.quantity > 1 ? false : true}
                    />
                    <Text
                      minW={"4"}
                      color={"#000"}
                      fontWeight={"medium"}
                      fontSize={15}
                      textAlign={"center"}
                    >
                      {e.quantity}
                    </Text>
                    <IconButton
                      rounded={"lg"}
                      size={"sm"}
                      bg={"#fff"}
                      icon={<FiPlus color="#000" />}
                      _focusVisible={{
                        outline: "none",
                      }}
                      onClick={() => addHandler(e)}
                      isDisabled={e.quantity < e.stock ? false : true}
                    />
                  </HStack>
                  <Icon
                    as={IoMdClose}
                    color={"#e04e61"}
                    w={"5"}
                    h={"5"}
                    alignSelf={"center"}
                    onClick={() => deleteHandler(e)}
                    cursor={"pointer"}
                  />
                </HStack>
              </Stack>
            ))
          ) : (
            <Image
              src={emptyBox}
              objectFit={"contain"}
              w={"12"}
              h={"12"}
              alignSelf={"center"}
            />
          )}
        </VStack>
      </VStack>

      <HStack w={"60"} alignSelf={"flex-end"}>
        <GradiantBtn
          title={"Хадгалах"}
          onClick={submitHandler}
          isDisabled={
            address &&
            phoneNo &&
            date &&
            selectedSecondSubArea &&
            selectedOrderType &&
            selectedProduct.length > 0 &&
            selectedProduct.every((e) => e.stock) &&
            !isDisabled
              ? false
              : true
          }
        />
      </HStack>
    </VStack>
  );
};

export default RightSide;
