import { BsPinMapFill } from "react-icons/bs";
import { TbMapPin2 } from "react-icons/tb";
import { PiShoppingCartBold, PiHeadsetFill } from "react-icons/pi";
import { BiSolidCar } from "react-icons/bi";
import { LuBarChart3 } from "react-icons/lu";

export const navData = [
  {
    title: "Захиалга",
    toLink: "/",
    icon: BsPinMapFill,
  },
  {
    title: "Бараа",
    toLink: "/product",
    icon: PiShoppingCartBold,
  },
  {
    title: "Жолооч",
    toLink: "/driver",
    icon: BiSolidCar,
  },
  {
    title: "Бүс",
    toLink: "/zone",
    icon: TbMapPin2,
  },
  {
    title: "Оператор",
    toLink: "/operator",
    icon: PiHeadsetFill,
  },
  {
    title: "Статистик мэдээлэл",
    toLink: "/dashboard",
    icon: LuBarChart3,
  },
  // {
  //   title: "Тохиргоо",
  //   toLink: "/settings",
  //   icon: FiSettings,
  //   subTitle: [
  //     {
  //       title: "Хэрэглэгч",
  //       toLink: "/settings/admin",
  //     },
  //     {
  //       title: "Мэдэгдэл",
  //       toLink: "/settings/user",
  //     },
  //   ],
  // },
];
