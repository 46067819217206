import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Button,
  Spinner,
  Center,
  VStack,
  MenuItem,
  useToast,
  useDisclosure,
  Portal,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import axios from "../../utils/axios";
import Cookies from "js-cookie";
import DeleteModal from "../../components/modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import DetailDrawer from "./utils/DetailDrawer";

const ListTable = ({
  data,
  headerData,
  isLoading,
  setTrigger,
  currentPage,
  indexArr,
}) => {
  // console.log("OrderList Table -->", data);
  const [isDisabled, setIsDisabled] = useState(false);
  const [productId, setProductId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  //
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [image, setImage] = useState("");
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [phoneColorMap, setPhoneColorMap] = useState({});

  const clearData = () => {
    setProductName("");
    setPrice("");
    setStock("");
    setImage("");
  };

  const getRandomColor = () => {
    // Generate a random color in hexadecimal format
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  React.useEffect(() => {
    const colorMap = {};
    const phoneCount = {};

    // Count the occurrences of each phoneNo
    data.forEach((item) => {
      phoneCount[item.phoneNo] = (phoneCount[item.phoneNo] || 0) + 1;
    });

    // Assign colors only to phoneNo that appear more than once
    data.forEach((item) => {
      if (phoneCount[item.phoneNo] > 1 && !colorMap[item.phoneNo]) {
        colorMap[item.phoneNo] = getRandomColor();
      }
    });

    setPhoneColorMap(colorMap);
  }, [data]);

  const deleteHandler = () => {
    if (orderId) {
      setIsDisabled(true);
      axios
        .delete("/api/order/", {
          data: {
            orderId: orderId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setOrderId("");
          setShowModal(false);
          setTrigger(true);
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack w={"full"}>
      <TableContainer w={"full"} pos={"relative"}>
        <Table border={"1px"} borderColor={"#ECECEC"} size={"sm"}>
          {headerData ? (
            <Thead bg={"#f9f9f9"} h={"8"}>
              <Tr>
                {headerData.map((e, i) => (
                  <Th
                    key={i}
                    borderRight={"1px"}
                    borderRightColor={"#ECECEC"}
                    fontSize={13}
                    fontWeight={"medium"}
                    textTransform={"initial"}
                  >
                    {e}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}
          {!isLoading && data.length > 0
            ? data.map((e, i) => (
                <Tbody key={i}>
                  <Tr w={"full"}>
                    <Td
                      w={"6"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      align={"center"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {indexArr[i]}
                      </Text>
                    </Td>
                    {/* ------------ Хугацаа ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {e.createdAt ? e.createdAt : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Бараа ------------ */}
                    <Td
                      maxW={"96"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      whiteSpace={"pre-wrap"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={"normal"}
                        color={"#000"}
                        noOfLines={1}
                      >
                        {e.products[0].product?.name
                          ? e.products[0].product.name
                          : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Орчим ------------ */}
                    <Td
                      maxW={"80"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      whiteSpace={"pre-wrap"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={"normal"}
                        color={"#000"}
                        noOfLines={1}
                      >
                        {e.area?.areaName
                          ? e.area.areaName
                          : "Устгагдсан орчим"}
                      </Text>
                    </Td>
                    {/* ------------ Хаяг ------------ */}
                    <Td
                      maxW={"48"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      whiteSpace={"pre-wrap"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={"normal"}
                        color={"#000"}
                        noOfLines={1}
                      >
                        {e.address ? e.address : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Утас ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      // bg={phoneColorMap[e.phoneNo] || "#fff"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={
                          phoneColorMap[e.phoneNo] ? "bold" : "normal"
                        }
                        color={phoneColorMap[e.phoneNo] || "#000"}
                        // color={getRandomColor() || "#000"}
                      >
                        {e.phoneNo ? e.phoneNo : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Тайлбар ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                      whiteSpace={"pre-wrap"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={"normal"}
                        color={"#000"}
                        noOfLines={1}
                      >
                        {e.description ? e.description : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Захиалга ------------ */}
                    <Td
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {e.orderType
                          ? e.orderType === "call"
                            ? "Утас"
                            : "Facebook"
                          : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Админ ------------ */}
                    <Td
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text fontSize={14} fontWeight={"normal"} color={"#000"}>
                        {e.admin?.username ? e.admin.username : "-"}
                      </Text>
                    </Td>
                    {/* ------------ Төлөв ------------ */}
                    <Td
                      w={"40"}
                      flexWrap={"wrap"}
                      whiteSpace={"normal"}
                      borderRight={"1px"}
                      borderRightColor={"#ECECEC"}
                      justify={"flex-start"}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={"normal"}
                        color={
                          e.status === "pending"
                            ? "#F07F1F"
                            : e.status === "driverTaken"
                            ? "#0042FF"
                            : e.status === "delivered"
                            ? "#45A735"
                            : "#ff0000"
                        }
                      >
                        {e.status
                          ? e.status === "pending"
                            ? "Хүлээгдэж буй"
                            : e.status === "notPaid"
                            ? "Төлөгдөөгүй"
                            : e.status === "driverTaken"
                            ? "Жолооч авсан"
                            : e.status === "delivered"
                            ? "Хүргэгдсэн"
                            : "Цуцлагдсан"
                          : "-"}
                      </Text>
                    </Td>
                    <Td w={"20"} py={"1"}>
                      <Menu
                        zIndex={99}
                        gutter={1}
                        autoSelect={false}
                        placement={"bottom"}
                      >
                        <MenuButton
                          as={Button}
                          bg={"#fff"}
                          borderRadius="none"
                          borderWidth="1px"
                          color={"#000"}
                          fontWeight={"normal"}
                          fontSize={13}
                          h={"8"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          rightIcon={<Icon as={IoIosArrowDown} />}
                          _focus={{ outline: "none", bg: "#fff" }}
                          _expanded={{
                            bg: "#fff",
                          }}
                          _hover={{
                            bg: "#fff",
                          }}
                          _active={{
                            bg: "#fff",
                          }}
                        >
                          Үйлдэл
                        </MenuButton>

                        <Portal>
                          <MenuList
                            minW="24"
                            w={"max-content"}
                            p={"0"}
                            rounded={"none"}
                            shadow={"md"}
                          >
                            <MenuItem
                              color={"#000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                setDrawerData(e);
                                // setShowDrawer(true);
                                onOpen();
                              }}
                            >
                              Дэлгэрэнгүй
                            </MenuItem>
                            <MenuItem
                              color={"#000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                navigate("/order", {
                                  state: {
                                    page: currentPage,
                                    navigate: "/",
                                    ...e,
                                  },
                                });
                              }}
                            >
                              Засварлах
                            </MenuItem>
                            <MenuItem
                              color={"#ff0000"}
                              fontWeight={"normal"}
                              fontSize={13}
                              onClick={() => {
                                setOrderId(e._id);
                                setShowModal(true);
                              }}
                            >
                              Устгах
                            </MenuItem>
                          </MenuList>
                        </Portal>
                      </Menu>
                    </Td>
                  </Tr>
                </Tbody>
              ))
            : null}
        </Table>
      </TableContainer>
      {isLoading && (
        <Center w={"full"} h={"10"}>
          <Spinner color={"#F07F1F"} />
        </Center>
      )}
      {!isLoading && data.length === 0 && (
        <VStack w={"full"} spacing={1} py={"2"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      <DetailDrawer data={drawerData} isOpen={isOpen} onClose={onClose} />

      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        isDisable={isDisabled}
        deleteHandler={deleteHandler}
      />
    </VStack>
  );
};

export default ListTable;
