import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Icon,
  Stack,
} from "@chakra-ui/react";
import axios from "../../utils/axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { IoClose } from "react-icons/io5";
import GradiantBtn from "../../components/btn/GradiantBtn";
import DeleteModal from "../../components/modal/DeleteModal";
import { FiInfo } from "react-icons/fi";

const DriverArea = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteAreaId, setDeleteAreaId] = useState("");
  //
  const [area, setArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [subArea, setSubArea] = useState([]);
  const [selectedSubArea, setSelectedSubArea] = useState("");
  const [selectedSecondSubArea, setSelectedSecondSubArea] = useState([]);
  const location = useLocation();
  const toast = useToast();
  const id = "toast";
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (trigger && location?.state?._id) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/driver/second_sub", {
          params: {
            driverId: location.state._id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("area list --------->", result.data);
          setData(result.data.data);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  useEffect(() => {
    setReload(true);
    let source = axios.CancelToken.source();
    let cancel = false;
    axios
      .get("/api/area/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((result) => {
        if (cancel) return;
        setReload(false);
        // console.log("zone list --------->", result.data);
        setArea(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        setReload(false);
      });
    return () => {
      cancel = true;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedArea) {
      setSelectedSubArea("");
      setReload(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/area/sub", {
          params: { areaId: selectedArea },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setReload(false);
          // console.log("sub list --------->", result.data);
          setSubArea(result.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setReload(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [selectedArea]);

  const selectedSecondSubHandler = (value) => {
    // console.log("selectedSecondSubHandler -->", value, selectedSecondSubArea);
    if (selectedSecondSubArea.some((e) => e._id === value._id)) {
      setSelectedSecondSubArea(
        selectedSecondSubArea.filter((e) => e._id !== value._id)
      );
    } else {
      setSelectedSecondSubArea([...selectedSecondSubArea, value]);
    }
  };

  const secondSubRemoveHandler = (value) => {
    setSelectedSecondSubArea(
      selectedSecondSubArea.filter((e) => e._id !== value._id)
    );
  };

  const submitHandler = () => {
    if (location.state._id && selectedSecondSubArea.length > 0) {
      setIsDisabled(true);
      axios
        .put(
          "/api/driver/second_sub",
          {
            driverId: location.state._id,
            secondSubAreaIds: selectedSecondSubArea.map((e) => e._id),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          // console.log(result.data);
          if (result.data.success) {
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
            setSelectedSecondSubArea([]);
            onClose();
            setTrigger(true);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
          setIsDisabled(false);
        });
    }
  };

  const deleteHandler = () => {
    if (location.state._id && deleteAreaId) {
      setIsDisabled(true);
      axios
        .delete("/api/driver/second_sub", {
          data: {
            driverId: location.state._id,
            secondSubAreaId: deleteAreaId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          // console.log(result.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "success",
              description: "Амжилттай!",
            });
          }
          setDeleteAreaId("");
          setShowModal(false);
          setTrigger(true);
          setIsDisabled(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsDisabled(false);
          if (!toast.isActive(id)) {
            toast({
              id,
              duration: 2000,
              position: "top",
              status: "error",
              description: err.response.data.error
                ? err.response.data.error
                : "Алдаа гарлаа!",
            });
          }
        });
    }
  };

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <Stack
        direction={["column", "column", "column", "column", "row", "row"]}
        w={"full"}
        align={"flex-start"}
        justify={"space-between"}
      >
        <HStack
          w={"max-content"}
          bg={"#f9f9f9"}
          py={"2"}
          px={"7"}
          rounded={"sm"}
          borderWidth={"thin"}
          // borderLeftColor={"#F07F1F"}
          // borderLeftWidth={"medium"}
          align={"flex-start"}
          spacing={10}
        >
          <HStack>
            <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
              Овог нэр:
            </Text>
            <Text
              color={"#000"}
              fontWeight={"medium"}
              fontSize={14}
              textTransform={"capitalize"}
            >
              {location?.state?.firstName + " " + location?.state?.lastName}
            </Text>
          </HStack>
          <HStack>
            <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
              Утас:
            </Text>
            <Text color={"#000"} fontWeight={"medium"} fontSize={14}>
              {location?.state?.phoneNo}
            </Text>
          </HStack>
        </HStack>
        <HStack w={"60"}>
          <GradiantBtn
            title={"Орчим нэмэх"}
            onClick={onOpen}
            isDisabled={isDisabled}
          />
        </HStack>
      </Stack>
      {isLoading ? (
        <Spinner alignSelf={"center"} color={"#F07F1F"} />
      ) : data.length > 0 ? (
        <SimpleGrid w={"full"} columns={[1, 1, 2, 2, 4, 4]} gap={6}>
          {data.map((e, i) => (
            <VStack
              key={e._id}
              w={"full"}
              align={"flex-start"}
              bg={"#f9f9f9"}
              py={"4"}
              px={"6"}
              rounded={"sm"}
              borderWidth={"thin"}
            >
              <HStack w={"full"} align={"flex-start"} justify={"space-between"}>
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  Дүүрэг:
                </Text>
                <Text
                  color={"#000"}
                  fontWeight={"medium"}
                  fontSize={14}
                  textAlign={"end"}
                >
                  {e.mainArea}
                </Text>
              </HStack>
              <HStack w={"full"} align={"flex-start"} justify={"space-between"}>
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  Чиглэл:
                </Text>
                <Text
                  color={"#000"}
                  fontWeight={"medium"}
                  fontSize={14}
                  textAlign={"end"}
                >
                  {e.subArea}
                </Text>
              </HStack>
              <HStack w={"full"} align={"flex-start"} justify={"space-between"}>
                <Text color={"#000"} fontWeight={"normal"} fontSize={14}>
                  Орчим:
                </Text>
                <Text
                  color={"#000"}
                  fontWeight={"medium"}
                  fontSize={14}
                  textAlign={"end"}
                >
                  {e.areaName}
                </Text>
              </HStack>

              <Button
                variant={"link"}
                alignSelf={"center"}
                color={"#ff0000"}
                fontWeight={"medium"}
                fontSize={14}
                onClick={() => {
                  setDeleteAreaId(e._id);
                  setShowModal(true);
                }}
              >
                Устгах
              </Button>
            </VStack>
          ))}
        </SimpleGrid>
      ) : (
        <VStack w={"full"} spacing={1} py={"2"} mt={"10"}>
          <Icon as={FiInfo} w={"5"} h={"5"} />
          <Text fontSize={13} color={"#000"} fontWeight={"normal"}>
            Мэдээлэл олдсонгүй
          </Text>
        </VStack>
      )}

      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        isDisable={isDisabled}
        deleteHandler={deleteHandler}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent w={["sm", "sm", "sm", "sm", "md", "md"]} rounded={"sm"}>
          <ModalHeader fontSize={17}>Орчим нэмэх</ModalHeader>
          <ModalBody w={"full"} px={"6"}>
            <VStack w={"full"}>
              <VStack w={"full"} align={"flex-start"} spacing={0}>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Дүүрэг:
                </Text>
                <Select
                  w={"full"}
                  bg={"#fff"}
                  size={"md"}
                  rounded={"md"}
                  fontWeight={"normal"}
                  fontSize={14}
                  color={"#000"}
                  value={selectedArea}
                  onChange={(e) => setSelectedArea(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#F07F1F",
                  }}
                  _hover={{
                    outline: "none",
                    borderColor: "#F07F1F",
                  }}
                >
                  <option selected hidden disabled value="">
                    -
                  </option>
                  {area.length > 0
                    ? area.map((e) => (
                        <option key={e._id} value={e._id}>
                          {e.areaName}
                        </option>
                      ))
                    : null}
                </Select>
              </VStack>
              <VStack w={"full"} align={"flex-start"} spacing={0}>
                <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                  Чиглэл:
                </Text>
                <Select
                  w={"full"}
                  bg={"#fff"}
                  size={"md"}
                  rounded={"md"}
                  fontWeight={"normal"}
                  fontSize={14}
                  color={"#000"}
                  value={selectedSubArea}
                  onChange={(e) => setSelectedSubArea(e.target.value)}
                  _focusVisible={{
                    outline: "none",
                    borderColor: "#F07F1F",
                  }}
                  _hover={{
                    outline: "none",
                    borderColor: "#F07F1F",
                  }}
                >
                  <option selected hidden disabled value="">
                    -
                  </option>
                  {subArea.length > 0
                    ? subArea.map((e) => (
                        <option key={e._id} value={e._id}>
                          {e.areaName}
                        </option>
                      ))
                    : null}
                </Select>
              </VStack>
              {selectedSubArea.length > 0 ? (
                <VStack w={"full"} align={"flex-start"} spacing={0}>
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    Орчим:
                  </Text>
                  <VStack w={"full"} align={"flex-start"} spacing={1}>
                    {subArea
                      .filter((e) => e._id === selectedSubArea)
                      .map((filteredSubArea) =>
                        filteredSubArea.secondSubAreas.map((a) => (
                          <Checkbox
                            key={a._id}
                            colorScheme="orange"
                            size={"md"}
                            value={selectedSecondSubArea}
                            onChange={() => selectedSecondSubHandler(a)}
                            isChecked={selectedSecondSubArea.some(
                              (e) => e._id === a._id
                            )}
                            css={`
                              > span:first-of-type {
                                box-shadow: unset;
                              }
                            `}
                          >
                            <Text
                              fontSize={14}
                              color="#000"
                              fontWeight={"normal"}
                            >
                              {a.areaName}
                            </Text>
                          </Checkbox>
                        ))
                      )}
                  </VStack>
                </VStack>
              ) : null}
              {selectedSecondSubArea.length > 0 ? (
                <VStack w={"full"} align={"flex-start"} spacing={0}>
                  <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
                    Сонгогдсон орчим:
                  </Text>
                  <VStack
                    w={"full"}
                    align={"flex-start"}
                    justify={"flex-start"}
                    borderWidth={"thin"}
                    rounded={"md"}
                    bg={"#fff"}
                    py={"2"}
                    px={"2"}
                    spacing={2}
                  >
                    {selectedSecondSubArea.map((e, i) => (
                      <HStack
                        key={i}
                        w={"full"}
                        bg={"#f9f9f9"}
                        rounded={"md"}
                        py={"2"}
                        pl={"3"}
                        pr={"2"}
                        align={"flex-start"}
                        justify={"space-between"}
                      >
                        <Text
                          key={e._id}
                          fontSize={14}
                          color="#000"
                          fontWeight={"medium"}
                        >
                          {i + 1 + ". "}
                          <Text as={"span"} fontWeight={"normal"}>
                            {e.areaName}
                          </Text>
                        </Text>

                        <Icon
                          as={IoClose}
                          w={"5"}
                          h={"5"}
                          color={"#ff0000"}
                          onClick={() => secondSubRemoveHandler(e)}
                          cursor={"pointer"}
                        />
                      </HStack>
                    ))}
                  </VStack>
                </VStack>
              ) : null}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              w={"full"}
              h={"9"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              mr={3}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              onClick={() => {
                onClose();
                setSelectedSecondSubArea([]);
                setSelectedArea("");
              }}
            >
              Болих
            </Button>
            <Button
              w={"full"}
              h={"9"}
              bg={"#F07F1F"}
              color={"#fff"}
              rounded={"md"}
              fontSize={14}
              fontWeight={"medium"}
              px={"8"}
              _focusVisible={{
                outline: "none",
              }}
              _hover={{
                bg: "#F28B33",
              }}
              onClick={submitHandler}
              isDisabled={!isDisabled ? false : true}
              isLoading={isDisabled}
              loadingText={"Уншиж байна..."}
            >
              Хадгалах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default DriverArea;
