import React, { useState } from "react";
import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const PasswordInput = ({
  title,
  value,
  onChange,
  onKeyDown,
  required,
  bgColor,
}) => {
  const [show, setShow] = useState(false);

  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={0}>
      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
        {required ? (
          <Text as={"span"} color={"#ff0000"}>
            *{" "}
          </Text>
        ) : null}
        {title + ":"}
      </Text>
      <InputGroup>
        <Input
          placeholder={"-"}
          w={"full"}
          bg={bgColor ? bgColor : "#F0F0F0"}
          fontWeight={"normal"}
          fontSize={14}
          color={"#000"}
          type={show ? "text" : "password"}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          _focusVisible={{
            outline: "none",
          }}
          _hover={{
            outline: "none",
          }}
          onKeyDown={onKeyDown}
        />
        <InputRightElement
          children={
            <Icon
              as={show ? AiOutlineEye : AiOutlineEyeInvisible}
              w={5}
              h={5}
              color={"gray.500"}
              onClick={() => setShow(!show)}
              cursor={"pointer"}
            />
          }
        />
      </InputGroup>
    </VStack>
  );
};

export default PasswordInput;
