import React, { useState } from "react";
import {
  Button,
  Center,
  Icon,
  Input,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import axios from "../../utils/axios";
import Cookies from "js-cookie";

const ImageUpload = ({ title, setData, required, bgColor }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new window.Image();

        img.onload = function () {
          setIsDisabled(true);
          const formData = new FormData();
          formData.append("image", file);
          axios
            .post("/api/product/image", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((result) => {
              // console.log(result.data);
              if (result.data.success) {
                setData(result.data.data);
              }
              setIsDisabled(false);
            })
            .catch((err) => {
              // console.log(err);

              setIsDisabled(false);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  duration: 2000,
                  position: "top",
                  status: "error",
                  description: err.response.data.error
                    ? err.response.data.error
                    : "Алдаа гарлаа!",
                });
              }
            });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <VStack w={"full"} align={"flex-start"} spacing={0}>
      <Text fontSize={14} color={"#000"} fontWeight={"medium"}>
        {required ? (
          <Text as={"span"} color={"#ff0000"}>
            *{" "}
          </Text>
        ) : null}
        {title + ":"}
      </Text>
      <VStack w={"full"}>
        <Input
          type="file"
          accept="image/*"
          // onChange={handleImageUpload}
          onChange={(event) => fileChangedHandler(event)}
          style={{ display: "none" }}
        />
        <Button
          w={"full"}
          h={"9"}
          bg={bgColor ? bgColor : "#F8F8F8"}
          borderWidth={"thin"}
          rounded="md"
          py={"2"}
          fontSize={15}
          onClick={() =>
            document.querySelectorAll('input[type="file"]')[0].click()
          }
          isDisabled={isDisabled}
        >
          {isDisabled ? (
            <Spinner mr={"2"} size={"sm"} />
          ) : (
            <Icon as={AiOutlineCloudUpload} w={"6"} h={"6"} color={"#F07F1F"} />
          )}
        </Button>
      </VStack>
    </VStack>
  );
};

export default ImageUpload;
